/* eslint-disable indent */
import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
  padding: 5px;
  padding-left: 0px;
  font-size: 12px;
  margin-top: 10px;
  background-color: #fff;
  color: #444;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);

  /* &:hover {
    background: ${shade(0.2, 'rgba(128, 128, 128, 0.04)')};
  } */
`;

export const Status = styled.div`
  ${(props) =>
    props.read &&
    css`
      margin: 5px;
      margin-right: 7px;
    `}
  div {
    ${(props) =>
      props.read &&
      css`
        display: none;
      `}
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #7dac34;
    margin: 5px;
    margin-right: 7px;
  }
`;

export const Text = styled.div`
  flex: 1;
  text-align: left;
  /* white-space: nowrap; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
`;

export const Tools = styled.div`
  display: flex !important;
  flex-direction: column;
  margin: 0px 2px;

  .iconTools {
    margin-top: 10px;
  }
  svg {
    color: #7dac34;
  }
`;
