import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

import { useModal } from '../../../../../../hooks/Modal';
import { FarmContext } from '../../index';
import { DashboardContext } from '../../../..';
import * as S from './styled';
import { Chip } from '../../../../../../components/Chip';
import { FarmShareModel } from '../../../../../../Models/FarmShareModel';
import { useAuth } from '../../../../../../hooks/Auth';

const ShareModal = ({
  messageDialog = 'Deseja realmente fazer isso ?',
  alert = '',
  farm,
  ...props
}) => {
  const { user } = useAuth();
  const { closeModal, modalIsOpen, modalStyles } = useModal();
  const {
    shareFarmId,
    setShareFarmId,
    handleShareFarm,
    handleDeleteShareFarm,
    handleUpdateShareFarm,
    shareList
  } = useContext(FarmContext);
  const [farmShare, setFarmShare] = useState(new FarmShareModel({}));

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      {...props}
    >
      <S.DialogContainer>
        <p>{alert}</p>
        <S.Text>{messageDialog}</S.Text>
        <S.InputEmail
          name="email"
          placeholder="Email"
          value={farmShare.email}
          onChange={(e) => {
            setFarmShare({ ...farmShare, email: e.target.value });
          }}
        />
        <div
          style={{
            padding: '10px',
            borderRadius: '10px',
            marginTop: '20px'
          }}
        >
          <S.Text>Permissões</S.Text>
          <S.PermissionTags>
            <Chip text="Fazendas" selected disabled />
            <Chip
              text="Talhões"
              selected={farmShare.share_Field}
              onChange={() => {
                if (farmShare.share_Subdivision) return;
                setFarmShare({
                  ...farmShare,
                  share_Field: !farmShare.share_Field
                });
              }}
            />
            <Chip
              text="Subdivisões"
              selected={farmShare.share_Subdivision}
              onChange={() => {
                if (farmShare.share_Report) return;
                setFarmShare({
                  ...farmShare,
                  share_Subdivision: !farmShare.share_Subdivision,
                  share_Field: true
                });
              }}
            />
            <Chip
              text="Caminhamentos"
              selected={farmShare.share_FieldMapping}
              onChange={() => {
                if (farmShare.share_Report) return;
                setFarmShare({
                  ...farmShare,
                  share_FieldMapping: !farmShare.share_FieldMapping
                });
              }}
            />
            <Chip
              text="Pontos"
              selected={farmShare.share_DataCollectionPoint}
              onChange={() => {
                if (farmShare.share_Report) return;
                setFarmShare({
                  ...farmShare,
                  share_DataCollectionPoint:
                    !farmShare.share_DataCollectionPoint
                });
              }}
            />
            <Chip
              text="Relatórios"
              selected={farmShare.share_Report}
              onChange={() => {
                setFarmShare({
                  ...farmShare,
                  share_Field: true,
                  share_Subdivision: true,
                  share_FieldMapping: true,
                  share_DataCollectionPoint: true,
                  share_Report: !farmShare.share_Report
                });
              }}
            />
          </S.PermissionTags>
        </div>
        <S.ButtonsContainer>
          <S.ConfirmationButton
            onClick={() => {
              if (!farmShare.farm_share_control_id) {
                farmShare.farm_id = shareFarmId.features[0].properties.farm_id;
                farmShare.created_by = user.user_id;
                handleShareFarm(farmShare);
                setFarmShare(FarmShareModel({}));
              } else {
                handleUpdateShareFarm(farmShare);
                setFarmShare(FarmShareModel({}));
              }
            }}
            style={{ backgroundColor: '#79AC34' }}
          >
            {!farmShare.farm_share_control_id ? 'COMPARTILHAR' : 'SALVAR'}
          </S.ConfirmationButton>
          <S.ConfirmationButton
            onClick={() => {
              closeModal();
              setShareFarmId();
            }}
            style={{ backgroundColor: '#d62222' }}
          >
            CANCELAR
          </S.ConfirmationButton>
        </S.ButtonsContainer>
        <div
          style={{
            padding: '10px',
            borderRadius: '10px',
            marginTop: '20px'
          }}
        >
          <S.Container>
            {shareList.map((sharedUser) => (
              <S.ListCardContainer key={sharedUser.farm_share_control_id}>
                <S.ListCardContainerText>
                  <S.ListCardText>
                    <h6>E-mail:</h6>
                    <span>{sharedUser.email} </span>
                  </S.ListCardText>
                  <S.ListCardText>
                    <h6>Permissões:</h6>
                    <span>Fazenda</span>
                    {sharedUser.share_Field && <span>Talhões</span>}
                    {sharedUser.share_Subdivision && <span>Subdivisões</span>}
                    {sharedUser.share_FieldMapping && (
                      <span>Caminhamentos</span>
                    )}
                    {sharedUser.share_DataCollectionPoint && (
                      <span>Pontos</span>
                    )}
                    {sharedUser.share_Report && <span>Relatorios</span>}
                  </S.ListCardText>
                </S.ListCardContainerText>
                <S.ListCardActions>
                  <button
                    type="button"
                    onClick={() => {
                      setFarmShare(FarmShareModel(sharedUser));
                    }}
                  >
                    <span>Editar</span>
                    <MdModeEdit />
                  </button>
                  <button
                    type="button"
                    className="simple-buttons"
                    onClick={() => {
                      handleDeleteShareFarm(sharedUser.farm_share_control_id);
                    }}
                  >
                    <span>Deletar</span>
                    <MdDelete />
                  </button>
                </S.ListCardActions>
              </S.ListCardContainer>
            ))}
          </S.Container>
        </div>
      </S.DialogContainer>
    </Modal>
  );
};

ShareModal.propTypes = {
  messageDialog: PropTypes.string,
  alert: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  farm: PropTypes.object
};

export default ShareModal;
