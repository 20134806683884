import React, { useContext, useState } from 'react';
import {
  MdClose,
  MdMarkEmailRead,
  MdMarkEmailUnread,
  MdDelete
} from 'react-icons/md';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { useMap } from '../../hooks/Map';
import { DashboardContext } from '../../pages/Dashboard';
import Button from '../Button';
import * as S from './styled';

const NotificationCard = ({
  children,
  read,
  onClickMarkRead,
  onClickDelete,
  actions = true,
  ...props
}) => {
  return (
    <S.Container read={read}>
      <S.Status read={read}>
        <div />
      </S.Status>
      <S.Text>{children}</S.Text>
      {actions && (
        <S.Tools>
          <button type="button" onClick={onClickMarkRead}>
            {read === true ? (
              <MdMarkEmailRead size={15} />
            ) : (
              <MdMarkEmailUnread size={15} />
            )}
          </button>
          <button type="button" onClick={onClickDelete}>
            <MdDelete size={15} className="iconTools" />
          </button>
        </S.Tools>
      )}
    </S.Container>
  );
};

export default NotificationCard;
