/* eslint-disable indent */
import styled, { css } from 'styled-components';

export const Container = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  height: calc(100% - 100px);
  display: flex;
`;

export const MultiMapConteiner = styled.div``;

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;

  ${(props) =>
    props.numberOfMaps === 4 &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    `}

  ${(props) =>
    props.numberOfMaps === 3 &&
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    `}

  ${(props) =>
    props.numberOfMaps === 2 &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    `}
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const SideNav = styled.div`
  height: 100%;
  width: 40px;
  transition: 0.5s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) =>
    props.isOpened &&
    !props.dataManagement &&
    css`
      min-width: 360px;
      max-width: 360px;
      padding: 20px 0 0px 0px;
    `}

  ${(props) =>
    props.dataManagement &&
    css`
      width: 100%;
      flex: 1;
      padding: 20px 0 0px 0px;
      transition: none;
    `}
`;

export const ButtonContainer = styled.div`
  min-height: 72px !important;
  max-height: 80px !important;
  width: 100%;
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #dcdcdc;

  button {
    background: transparent;
    border: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      //margin: 32px 0;

      transition: 0.8s;
      ${(props) =>
        props.isOpened &&
        css`
          -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
        `}
    }
  }
`;

export const NavContentContainer = styled.div`
  padding: 0 32px;
  width: 100%;
  height: 100%;
`;
