/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdDelete, MdSearch } from 'react-icons/md';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Pagination
} from '@mui/material';
import PuffLoader from 'react-spinners/PuffLoader';
import { DashboardContext } from '../../../../index';
import * as S from './styled';
import { useModal } from '../../../../../../hooks/Modal';
import Button from '../../../../../../components/Button';
import { useMap } from '../../../../../../hooks/Map';
import { useAuth } from '../../../../../../hooks/Auth';
import api from '../../../../../../services/api';
import { appConfig } from '../../../../../../config';
import ViewRoutine from './components/ViewRoutine';

const Monitoring = () => {
  const { selectedFarmId } = useMap();
  const { token, user, getNotifications } = useAuth();
  const { openModal } = useModal();
  const {
    setOpendedModal,
    setItemSelectedVisible,
    setUpdateScreen,
    setSelectedRoutine,
    updateScreen,
    setSelectedMonitoring
  } = useContext(DashboardContext);

  const [loading, setLoading] = useState(false);
  const [nextRoutineList, setNextRoutineList] = useState([]);
  const [doneRoutineList, setDoneRoutineList] = useState([]);
  const [totalPagesNextRoutine, setTotalPagesNextRoutine] = useState();
  const [totalPagesDoneRoutine, setTotalPagesDoneRoutine] = useState();
  const [searchField, setSearchField] = useState('');

  const getNextRoutine = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['status', 'af01fe97-b627-45d4-b620-43cbd5958adb'], // status ativo
        ['page', pageNumber],
        ['itensPerPage', 999],
        ['filter', searchField || '']
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByStatus?itensPerPage=99999`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setNextRoutineList(response.data.data.routines);
      setTotalPagesNextRoutine(response.data.data.pagination.totalPages);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getDoneRoutine = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['status', 'bb4d9732-1a3c-4eff-a09a-43bdd44de297'], // status concluído
        ['page', pageNumber],
        ['itensPerPage', 999],
        ['filter', searchField || '']
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByStatus?itensPerPage=99999`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setDoneRoutineList(response.data.data.routines);
      setTotalPagesDoneRoutine(response.data.data.pagination.totalPages);

      setUpdateScreen(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getRoutineById = async (id, selectedVisible) => {
    const params = new URLSearchParams([['routine_id', id]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/ListByRoutineId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setSelectedRoutine(response.data.data);

      if (selectedVisible) {
        setItemSelectedVisible('viewRoutine');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePageNextRoutine = (event, value) => {
    getNextRoutine(value);
  };

  const handleChangePageDoneRoutine = (event, value) => {
    getDoneRoutine(value);
  };

  const formatDate = (date) => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  useEffect(() => {
    getNextRoutine();
    getDoneRoutine();
  }, []);

  useEffect(() => {
    if (updateScreen) {
      getNextRoutine();
      getDoneRoutine();
    }
  }, [updateScreen]);

  useEffect(() => {
    if (searchField === '') {
      getNextRoutine();
      getDoneRoutine();
    }
  }, [searchField]);

  return (
    <S.Container>
      <S.SearchAreaContainer>
        <S.SearchArea>
          <MdSearch
            size={28}
            onClick={() => {
              getNextRoutine();
              getDoneRoutine();
            }}
          />
          <S.SearchInput
            type="text"
            placeholder="Pesquisa por pragas, talhões, etc..."
            value={searchField}
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
        </S.SearchArea>
        {/* <S.FilterWrapper>
          <MdFilterList />
          <span>Filtrar Por...</span>
        </S.FilterWrapper> */}
        <S.AddBtnContainer>
          <Button
            type="button"
            text="Nova Rotina"
            className="addBtn"
            onClick={() => {
              setOpendedModal('modalNewRoutine');
              openModal();
            }}
          />
        </S.AddBtnContainer>
      </S.SearchAreaContainer>
      <S.AddBtnContainer>
        <S.SubTitle>Em Andamento / Próximas</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        className="tabela"
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
            />
          </>
        ) : (
          <Table size="small" cellspacing="0">
            <TableHead>
              <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Data</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Tipo</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Local</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nextRoutineList?.map((row) => (
                <TableRow
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRoutineById(row.routine_id, true);
                    }}
                  >
                    {formatDate(row.init_date)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRoutineById(row.routine_id, true);
                    }}
                  >
                    {row.routine_type_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRoutineById(row.routine_id, true);
                    }}
                  >
                    {row.encompass_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      getRoutineById(row.routine_id, true);
                    }}
                  >
                    {row.status_name}
                  </TableCell>
                  <TableCell align="center">
                    <S.ButtonWrapper>
                      <button
                        type="button"
                        className="actionBtn"
                        onClick={() => {
                          getRoutineById(row.routine_id, false);
                          setOpendedModal('modalDeleteRoutine');
                          openModal();
                        }}
                      >
                        {/* <span style={{ color: '#00F' }}>Editar</span> */}
                        <MdDelete style={{ color: '#f00' }} />
                      </button>
                    </S.ButtonWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1} style={{ marginBottom: '10px' }}>
        <Pagination
          count={totalPagesNextRoutine}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageNextRoutine}
        />
      </Stack>
      <S.AddBtnContainer>
        <S.SubTitle>Monitoramentos Concluídos</S.SubTitle>
      </S.AddBtnContainer>
      <TableContainer
        className="tabela"
        style={{
          borderRadius: '8px',
          marginBottom: '10px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {loading ? (
          <>
            <PuffLoader
              color="#79ac34"
              loading={loading}
              size={30}
            />
          </>
        ) : (
          <Table size="small" cellspacing="0">
            <TableHead>
              <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Data</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Tipo</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Local</TableCell>
                <TableCell align="center" style={{ fontWeight: 'bold' }}>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doneRoutineList?.map((row) => (
                <TableRow>
                  <TableCell align="center">
                    {formatDate(row.init_date)}
                  </TableCell>
                  <TableCell align="center">
                    {row.routine_type_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.encompass_name}
                  </TableCell>
                  <TableCell align="center">
                    <S.ButtonWrapper>
                      <button
                        type="button"
                        style={{ backgroundColor: 'yellow', borderRadius: '5px' }}
                        className="actionBtn"
                        onClick={() => {
                          setSelectedMonitoring(row);
                          setOpendedModal('ModaExportRoutine');
                          openModal();
                        }}
                      >
                        <KeyboardDoubleArrowDownIcon style={{ color: '#000' }} />
                      </button>
                    </S.ButtonWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Stack spacing={1}>
        <Pagination
          count={totalPagesDoneRoutine}
          variant="outlined"
          shape="rounded"
          size="small"
          onChange={handleChangePageDoneRoutine}
        />
      </Stack>
    </S.Container>

  );
};

export default Monitoring;
