import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.header`
  height: 100px;
  width: 100%;
  padding: 40px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  > div {
    padding: 20px;
    img {
      width: 195px;
      height: auto;
    }
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
  }
`;
export const NotificationOptions = styled.div`
  position: relative;
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    border: none;
    background: #fff;
    div {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 22px;
        height: 22px;
      }
    }
    span {
      margin: 0 5px;
      margin-left: 0px;
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    right: 1;
    max-width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.3);
  }

  ul {
    list-style: none;
  }
`;

export const Notifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;

  div {
    position: relative;
    display: inline-block;
    > span {
      font-size: 11px;
      position: absolute;
      top: -3px;
      right: -5px;
      padding: 1px 4px;
      border-radius: 50%;
      background: #a0bd39;
      color: white;
      border: 1px solid #fff;
    }
  }

  > svg {
    width: 18px;
    height: 18px;
  }
`;
export const UserOptions = styled.div`
  position: relative;
  display: inline-block;

  .dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    max-width: 300px;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    button {
      width: 100%;
      height: 100%;
      padding: 10px;

      &:hover {
        background: ${shade(0.2, '#FFF')};
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    border: none;
    background: #fff;

    span {
      margin-left: 5px;
    }

    div {
      background: #a0bd39;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;
        color: #fff;
      }
    }
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const NotificationsContainer = styled.div`
  flex: 1;
  min-height: 50px;
  max-height: 300px;
  overflow: auto;
  padding: 5px;

  ${(props) =>
    props.loading === 'true' &&
    css`
      display: flex;
      justify-content: center;
      flex-direction: row;
    `}
`;

export const DropDownNotification = styled.ul`
  width: 100%;
  min-width: 200px;
  height: 250px;
  overflow-y: auto;
  padding: 5px;
  background: #ffffff;
`;
