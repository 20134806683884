/* eslint-disable prettier/prettier */
/* eslint-disable indent */
/* eslint-disable function-paren-newline */
import { yupResolver } from '@hookform/resolvers/yup';
import { kml } from '@tmcw/togeojson';
import * as turf from '@turf/turf';
import JSZip from 'jszip';
import proj4 from 'proj4';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  MdCloudUpload,
  MdDelete,
  MdEditLocation,
  MdKeyboardArrowRight,
  MdModeEdit,
  MdCheck,
  MdMap
} from 'react-icons/md';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import * as shp from 'shpjs';
import * as Yup from 'yup';
import Checkbox from 'react-custom-checkbox';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import InstructionsToast from '../../../../../../components/Toast/Instructions';
import { calculateArea } from '../../../../../../helpers/calculateArea';
import { parseCoordinatesMultiPolygonMultiFeaturesTo3857, parseMultiPolygonToPolygon } from '../../../../../../helpers/parseCoordinates';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import { useModal } from '../../../../../../hooks/Modal';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import ConfirmDialog from './components/ConfirmDialog';
import * as S from './styled';
import { appConfig } from '../../../../../../config';

export const FieldContext = createContext({});

const Field = () => {
  const {
    farm,
    setViewFields,
    removeAllInterections,
    addFields,
    addingField,
    setAddingField,
    field,
    setField,
    drawendField,
    selectedFarmId,
    renderFields,
    setListFields,
    renderFieldFromShapefile,
    removeFieldGeometries,
    modifyField,
    setControlState,
    setSelectedFieldToTimeline,
    selectedFarmGeoJson,
    zoomField,
    setShowButtonCarousel,
    removeWMSLayer,
    selectedFieldsToNDVI,
    setSelectedFieldsToNDVI,
    setDrawing
  } = useMap();
  const { openModal } = useModal();
  const [finnalyReq, setFinnalyReq] = useState(false);
  const {
    setDashboardState,
    dashboardState,
    fields,
    setFields,
    cropTypeName,
    setCropTypeName,
    oldCropTypeName,
    setOldCropTypeName,
    cropSubTypeName,
    setCropSubTypeName
   } =
    useContext(DashboardContext);
  const { user, token } = useAuth();
  const [deleteField, setDeleteField] = useState();
  const [cropType, setCropType] = useState();
  const [oldCropType, setOldCropType] = useState();
  const [cropSubType, setCropSubType] = useState();
  const [cropTypes, setCropTypes] = useState([]);
  const [cropSubTypes, setCropSubTypes] = useState([]);

  const [cropTypeId, setCropTypeId] = useState('');
  const [oldCropTypeId, setOldCropTypeId] = useState('');
  const [cropSubTypeId, setCropSubTypeId] = useState('');
  const [selectedFieldId, setSelectedFieldId] = useState([]);
  const [chekedAll, setCheckedAll] = useState(false);

  useEffect(async () => {
    try {
      const params = new URLSearchParams([['type_id', 'c826052d-3a59-4398-aec1-81193ccd2e42']]);
      const cropTypesResponse = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setCropTypes(cropTypesResponse.data.data.values);
    } catch (error) {
      setCropTypes([]);
      // eslint-disable-next-line no-console
      console.error(error);
    }

    try {
      const params = new URLSearchParams([['type_id', '4f7bca40-7ffd-4485-9627-e5d2ba90776c']]);
      const cropTypesResponse = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setCropSubTypes(cropTypesResponse.data.data.values);
    } catch (error) {
      setCropSubTypes([]);
      console.error(error);
    }
  }, []);

  // const getCropSubType = async (id) => {
  //   const params = new URLSearchParams([['cropTypeId', id]]);
  //   try {
  //     const cropSubTypesResponse = await api.get(
  //       `${appConfig.apiHosts.field}/Crop_Subtype/GetActiveCrop_SubtypeList`,
  //       {
  //         params,
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       }
  //     );
  //     setCropSubTypes(cropSubTypesResponse.data.data);
  //   } catch (error) {
  //     setCropSubTypes([]);
  //     // eslint-disable-next-line no-console
  //     console.error(error);
  //   }
  // };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  const onMultiRegisterField = async (geojsonField) => {
    let pFields;
    geojsonField.features[0].properties = {
      ...geojsonField.features[0].properties,
      farm_id: selectedFarmId,
      created_by: user.user_id,
      crop_type_id: '4a86e892-b7ac-4ae3-b0c3-b67ac025f362',
      crop_subtype_id: '4bff21c8-3aad-4f3d-ade3-38757518194b',
      previous_crop_type_id: '4a86e892-b7ac-4ae3-b0c3-b67ac025f362'
    };

    // geojsonField.features[0].geometry.coordinates.forEach((v) => {
    //   v.forEach((coord) => {
    //     coord.splice(2, 10);
    //   });
    // });

    if (geojsonField.features[0].geometry.type !== 'MultiPolygon') {
      geojsonField.features[0].geometry.type = 'MultiPolygon';
      geojsonField.features[0].geometry.coordinates[0] = [
        ...geojsonField.features[0].geometry.coordinates
      ];
    }

    try {
      if (geojsonField.features[0].geometry.coordinates[0].length > 0) {
        const newCoordinate = [];
        geojsonField.features[0].geometry.coordinates[0].forEach((coordinates) => {
          const coordinate = coordinates.map((item) => {
            const [longitude, latitude] = item;
            return [longitude, latitude];
          });
          newCoordinate.push(coordinate);
        });
        geojsonField.features[0].geometry.coordinates[0] = newCoordinate;
      }

      const response = await api.post(
        `${appConfig.apiHosts.field}/Field/PostField`,
        geojsonField,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // setDashboardState('dashboard@dashboard');
      // setDashboardState('dashboard@fields');
      // setFields((oldFields) => [...oldFields, response.data.data]);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const registerShapefile = (geojson) => {
    if (geojson.features.length > 1) {
      const generatedFields = [];
      toast(
        <Toast
          title="O shapefile contem multiplas geometrias "
          content={`as geometrias importadas serão registradas de forma numerada e sequencial, quantidade de geometrias: ${geojson.features.length}`}
        />,
        {
          type: 'default'
        }
      );
      geojson.features.forEach((value, index) => {
        value?.properties?.Talhao
          ? (value.properties.field_name = value?.properties?.Talhao)
          : (value.properties.field_name = `T${index + 1}`);
        const geoJson = {
          type: 'FeatureCollection',
          features: [value]
        };
        generatedFields.push(
          parseCoordinatesMultiPolygonMultiFeaturesTo3857(geoJson)
        );
      });
      generatedFields.map(async (geojsonField, index) => {
        if (
          !turf.booleanIntersects(
            selectedFarmGeoJson.features[0].geometry,
            geojsonField.features[0].geometry
          )
        ) {
          return toast(
            <Toast
              title="Talhão não importado"
              content="A geometria não está dentro dos limites da fazenda"
            />,
            {
              type: 'error'
            }
          );
        }
        await onMultiRegisterField(geojsonField);
        getFields();
        if (generatedFields.length === index) {
          setFinnalyReq(true);
        }

        return renderFieldFromShapefile(geojsonField);
      });
    } else {
      if (geojson.features[0].geometry.type !== 'Polygon') {
        return toast(
          <Toast
            title="O shapefile contem uma geometria diferente do tipo aceito"
            content={`Tipo Aceito: 'Polygon' - Tipo Enviado: '${geojson.features[0].geometry.type}'`}
          />,
          {
            type: 'error'
          }
        );
      }
      const parsedCoordinates = geojson.features[0].geometry.coordinates[0].map(
        (value) => proj4('EPSG:4326', 'EPSG:3857', [value[0], value[1]])
      );
      geojson.features[0].geometry.coordinates[0] = parsedCoordinates;
      if (
        !turf.booleanIntersects(
          geojson.features[0].geometry,
          farm.features[0].geometry
        )
      ) {
        return toast(<Toast title="Talhão fora dos limites da fazenda" />, {
          type: 'error'
        });
      }
      renderFieldFromShapefile(geojson);
      setDashboardState('dashboard@registerField_shapefile');
    }
    getFields();
  };

  function convertToLayerZip(buffer) {
    shp(buffer).then((geojson) => registerShapefile(geojson));
  }

  const convertToLayerKml = (buffer) => {
    const blob = new Blob([buffer], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    fetch(url)
      .then((response) => response.text())
      .then((xml) => {
        const kmlReadResult = kml(
          new DOMParser().parseFromString(xml, 'text/xml')
        );
        registerShapefile(kmlReadResult);
      });
  };

  const convertToLayerKmz = async (buffer) => {
    const blob = new Blob([buffer], { type: 'application/zip' });
    const zip = new JSZip();
    await zip.loadAsync(blob).then(async (zipped) => {
      const file = await zipped.file('doc.kml').async('blob');
      const url = URL.createObjectURL(file);
      fetch(url)
        .then((response) => response.text())
        .then((xml) => {
          const kmlReadResult = kml(
            new DOMParser().parseFromString(xml, 'text/xml')
          );
          registerShapefile(kmlReadResult);
        });
    });
  };

  const handleUploadFile = (file, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState !== 2 || reader.error) {
        return null;
      }
      if (
        type === 'application/zip' ||
        type === 'zip' ||
        type === 'application/x-zip-compressed'
      ) {
        return convertToLayerZip(reader.result);
      }
      if (type === 'application/vnd.google-earth.kml+xml' || type === 'kml') {
        return convertToLayerKml(reader.result);
      }
      if (type === 'application/vnd.google-earth.kmz' || type === 'kmz') {
        return convertToLayerKmz(reader.result);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const FieldFormSchemaValidation = Yup.object().shape({
    field_name: Yup.string().required('Nome do talhão é obrigatório')
  });
  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(FieldFormSchemaValidation)
  });
  const handleDeleteField = async (id) => {
    const params = new URLSearchParams([
      ['fieldId', id],
      ['status', false]
    ]);
    try {
      await api
        .delete(`${appConfig.apiHosts.field}/Field/DeleteField`, {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .finally(() => {
          removeFieldGeometries();
          getFields();
          toast(<Toast title="Talhão removido com sucesso" />, {
            type: 'success'
          });
        });
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível remover talhão."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const onSubmit = async (data) => {
    if (drawendField === false) {
      toast(
        <Toast
          title="Talhão não desenhado"
          content="Por favor desenhe o talhão no formato desejado"
        />,
        { type: 'warning' }
      );
      return;
    }
    if (cropTypeId === '') {
      toast(
        <Toast
          title="Favor informar o campo Cultura"
          content="Por favor complete o formulario de cadastro para cadastrar um talhão"
        />,
        { type: 'error' }
      );
      return;
    }

    field.features[0].properties = {
      field_name: data.field_name,
      crop_type_id: cropTypeId,
      crop_subtype_id: cropSubTypeId || null,
      previous_crop_type_id: oldCropTypeId || null,
      farm_id: farm.features[0].properties.farm_id,
      date_planting: data.date_planting,
      created_by: user.user_id
    };

    if (field.features[0].geometry.type !== 'MultiPolygon') {
      field.features[0].geometry.type = 'MultiPolygon';
      field.features[0].geometry.coordinates[0] = [
        ...field.features[0].geometry.coordinates
      ];
    }

    try {
      const response = await api.post(
        `${appConfig.apiHosts.field}/Field/PostField`,
        field,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const message = response.data.message.replaceAll("'", '');
      // setFields([...fields, field]);
      getFields();
      toast(<Toast title={message} />, { type: 'success' });
      setField(field);
      setAddingField(false);
      removeAllInterections();
      setCropType('');
      setOldCropType('');
      setCropSubType('');
      setTimeout(() => setDrawing(false), 2000);
    } catch (error) {
      toast(<Toast title="Não foi possivel salvar o talhão" />, {
        type: 'error'
      });
      removeFieldGeometries();
      getFields();
      addFields();
      setAddingField(true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const insertAllSelected = () => {
    setSelectedFieldId(fields.map((value) =>
      value.features[0].properties.field_id
    )
    );
  };

  const deleteMultipleFields = async () => {
    try {
      selectedFieldId.map(async (v) => {
        const params = new URLSearchParams([
          ['fieldId', v],
          ['status', false]
        ]);
        await api
          .delete(`${appConfig.apiHosts.field}/Field/DeleteField`, {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
        removeFieldGeometries();
        getFields();
        setCheckedAll(false);
        setSelectedFieldId([]);
      });
      toast(<Toast title="Remoção feita com sucesso" />, {
        type: 'success'
      });
    } catch {
      toast(
        <Toast
          title="Não foi possível remover o talhão."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  useEffect(() => {
    if (addingField) {
      toast(
        <InstructionsToast title="Desenhe a área do talhão da sua propriedade no mapa">
          <p>
            1. Use o mouse para desenhar a área correspondente.
            <br />
            2. Após desenhar o polígono no mapa clique em cadastrar talhão.
          </p>
        </InstructionsToast>,
        {
          type: 'default',
          autoClose: 10000,
          hideProgressBar: true,
          style: { marginTop: '25%' }
        }
      );
    }
  }, [addingField]);

  const getNames = async (value) => {
    try {
      const params = new URLSearchParams([['type_id', 'c826052d-3a59-4398-aec1-81193ccd2e42']]);
      const cropTypesResponse = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const paramsSub = new URLSearchParams([['type_id', '4f7bca40-7ffd-4485-9627-e5d2ba90776c']]);
      const cropSubTypesResponse = await api.get(
        `${appConfig.apiHosts.manager}/Value/List`,
        {
          params: paramsSub,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const filterCropTypeName = cropTypesResponse.data.data.values.filter((v) => v.value_Id === value.features[0].properties.crop_type_id)[0].name;
      const filterOldCropTypeName = cropTypesResponse.data.data.values.filter((v) => v.value_Id === value.features[0].properties.previous_crop_type_id)[0].name;
      const filterCropSubTypeName = cropSubTypesResponse.data.data.values.filter((v) => v.value_Id === value.features[0].properties.crop_subtype_id)[0].name;

      setCropTypeName(filterCropTypeName);
      setOldCropTypeName(filterOldCropTypeName);
      setCropSubTypeName(filterCropSubTypeName);

      setDashboardState('dashboard@editField');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getFields();
  }, []);

  const selectAllFieldsForRenderNDVI = () => {
    const selectedFields = fields.filter((f) => selectedFieldId.includes(f.features[0].properties.field_id));
    const convertedFields = selectedFields.map((f) => parseMultiPolygonToPolygon(f));
    setSelectedFieldsToNDVI(convertedFields);
    let union = null;
    let first = true;
    for (let i = 0; i < convertedFields.length; i++) {
      if (first) {
        union = convertedFields[i].features[0].geometry;
        first = false;
      } else {
        union = turf.union(union, convertedFields[i].features[0].geometry);
      }
    }
    setSelectedFieldToTimeline(union);
    setShowButtonCarousel(true);
  };

  return (
    <FieldContext.Provider value={{ getFields, handleDeleteField }}>
      <S.Container>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <SimpleButton
            text="Voltar"
            onClick={() => {
              setViewFields(false);
              removeAllInterections();
              removeWMSLayer();
              setAddingField(false);
              setDashboardState('dashboard@farm');
              setControlState(undefined);
            }}
          />
        </div>
        <S.Title>Adicionar talhão</S.Title>
        <S.SubTitle>
          Adicione o talhão para demarcar uma região da sua propriedade e
          monitora-la
        </S.SubTitle>

        {addingField ? (
          <S.FieldForm onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="field_name"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <TextField
                  type="text"
                  name="field_name"
                  id="outlined-basic"
                  label="Nome do talhão"
                  variant="outlined"
                  ref={register}
                  value={value}
                  onChange={onChange}
                />
            )}
            />
            {!!errors?.field_name && (
              <S.ErrorMessage>{errors?.field_name?.message}</S.ErrorMessage>
            )}
            <FormControl>
              <InputLabel>
                Cultura
              </InputLabel>
              <Select
                label="Cultura"
                placeholder="Cultura"
                name="crop_type"
                value={cropType}
                onChange={(e) => {
                  setCropType(e.target.value);
                  const CTypeId = cropTypes.filter(
                    (value) => value.name === e.target.value ?? value
                  )[0];
                  setCropTypeId(CTypeId.value_Id);
                }}
              >
                {cropTypes.map((v) => (
                  <MenuItem value={v.name} key={v.value_Id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>
                Cultura Anterior
              </InputLabel>
              <Select
                label="Cultura Anterior"
                placeholder="Cultura Anterior"
                name="previous_crop_type"
                value={oldCropType}
                onChange={(e) => {
                  setOldCropType(e.target.value);
                const COldTypeId = cropTypes.filter(
                  (value) => value.name === e.target.value ?? value
                )[0];
                setOldCropTypeId(COldTypeId.value_Id);
                }}
              >
                {cropTypes.map((v) => (
                  <MenuItem value={v.name} key={v.value_Id}>
                    {v.name}
                  </MenuItem>
                        ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>
                Cultivar
              </InputLabel>
              <Select
                label="Cultivar"
                placeholder="Cultivar"
                name="crop_subtype"
                value={cropSubType}
                onChange={(e) => {
                  setCropSubType(e.target.value);
                  const CSubTypeId = cropSubTypes.filter(
                    (value) => value.name === e.target.value ?? value
                  )[0];
                  setCropSubTypeId(CSubTypeId.value_Id);
                }}
              >
                {cropSubTypes.map((v) => (
                  <MenuItem value={v.name} key={v.value_Id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <span>Data de plantio</span>
              <Controller
                name="date_planting"
                control={control}
                defaultValue=""
                render={({ onChange, value }) => (
                  <TextField
                    type="date"
                    margin="dense"
                    fullWidth
                    size="medium"
                    value={value}
                    onChange={onChange}
                  />
                  )}
              />
            </FormControl>
            <Button style={{ marginBottom: 10 }} text="Cadastrar Talhão" />
          </S.FieldForm>
        ) : (
          <>
            <S.Title>Talhões Cadastrados</S.Title>
            <S.BtnContainer>
              {fields.length > 0 && (
                <S.BtnOptionsAction
                  onClick={() => {
                    if (selectedFieldId.length > 0) {
                      return setSelectedFieldId([]),
                        setCheckedAll(false);
                    }
                    insertAllSelected();
                    setCheckedAll(true);
                  }}
                >
                  <Checkbox
                    checked={chekedAll}
                    icon={(
                      <MdCheck
                        color="#507919"
                        size={16}
                        style={{ margin: 0 }}
                      />
                    )}
                    borderColor="#A0BD39"
                    borderRadius={2}
                    borderWidth={1}
                    size={13}
                    style={{ cursor: 'pointer' }}
                  />
                  <span className="selectAll">Selecionar todos</span>
                </S.BtnOptionsAction>
              )}
              {fields.length > 0 && (
                <S.BtnOptionsAction
                  onClick={() => {
                    deleteMultipleFields();
                  }}
                  type="button"
                >
                  <MdDelete size={18} />
                  <span>Deletar</span>
                </S.BtnOptionsAction>
              )}

              {fields.length > 0 && (
                <S.BtnOptionsAction onClick={() => selectAllFieldsForRenderNDVI()}>
                  <MdMap size={18} />
                  Visualizar
                </S.BtnOptionsAction>
              )}
            </S.BtnContainer>
            <S.RegisteredFields>
              {fields?.length === 0 ? (
                <p className="infoText">Sem talhões cadastrados.</p>
              ) : (
                <>
                  {fields.map((value) => (
                    <S.FieldCardContainer>
                      <S.FieldCardHeader>
                        <Checkbox
                          checked={selectedFieldId.includes(
                            value.features[0].properties.field_id
                          )}
                          icon={(
                            <MdCheck
                              color="#507919"
                              size={16}
                              style={{ margin: 0 }}
                            />
                          )}
                          borderColor="#A0BD39"
                          borderRadius={2}
                          borderWidth={1}
                          onChange={() => {
                            if (
                              selectedFieldId.includes(
                                value.features[0].properties.field_id
                              )
                            ) {
                              return selectedFieldId.splice(
                                selectedFieldId.indexOf(
                                  value.features[0].properties.field_id
                                ),
                                1
                              );
                            }
                            selectedFieldId.push(
                              value.features[0].properties.field_id
                            );
                          }}
                          size={12}
                          style={{ cursor: 'pointer' }}
                        />
                        <S.FieldCardText>
                          <span>
                            <b>{value.features[0].properties.field_name}</b>
                          </span>
                          <span>- {calculateArea(value)}</span>
                        </S.FieldCardText>
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedFieldToTimeline(value);
                            zoomField(value);
                            setShowButtonCarousel(true);
                          }}
                        >
                          <MdKeyboardArrowRight size={24} />
                        </button>
                      </S.FieldCardHeader>
                      <S.FieldCardActions>
                        <button
                          type="button"
                          onClick={() => {
                            modifyField(value);
                            getNames(value);
                          }}
                        >
                          <span>Editar</span>
                          <MdModeEdit />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setDeleteField(value);
                            openModal();
                          }}
                        >
                          <span>Deletar</span>
                          <MdDelete />
                        </button>
                      </S.FieldCardActions>
                    </S.FieldCardContainer>
                  ))}
                </>
              )}
            </S.RegisteredFields>
            <S.ActionCardContainer>
              <S.ActionCard
                onClick={() => {
                  addFields();
                  setAddingField(true);
                }}
              >
                <MdEditLocation size={28} color="#9FBC38" />
                <span>Desenhe o seu talhão</span>
                <p>Desenhe com o mouse no mapa ao lado</p>
              </S.ActionCard>
              <S.ActionCard>
                <label htmlFor="upload-file-field" className="label-upload">
                  <MdCloudUpload size={28} color="#9FBC38" />
                  <span>Fazer upload do talhão</span>
                  <p>Formatos Aceitos: Shapefile.zip, .kml, .kmz</p>
                  <input
                    type="file"
                    accept=".zip, .kmz, .kml"
                    id="upload-file-field"
                    multiple={false}
                    onChange={(e) => {
                      const qtdArray = e.target.files.length;
                      const ext = e.target.files[qtdArray - 1].name.split('.');
                      if (
                        e.target.files[qtdArray - 1].type !==
                        'application/zip' &&
                        e.target.files[qtdArray - 1].type !==
                        'application/vnd.google-earth.kml+xml' &&
                        e.target.files[qtdArray - 1].type !==
                        'application/vnd.google-earth.kmz' &&
                        e.target.files[qtdArray - 1].type !==
                        'application/x-zip-compressed' &&
                        ext[ext.length - 1] !== 'kmz' &&
                        ext[ext.length - 1] !== 'kml' &&
                        ext[ext.length - 1] !== 'zip'
                      ) {
                        return (
                          null,
                          toast(
                            <Toast
                              title="Shapefile no formato incorreto"
                              content="Formatos aceitos: .zip, .kml, .kmz "
                            />,
                            {
                              type: 'error'
                            }
                          )
                        );
                      }
                      handleUploadFile(
                        e.target.files[qtdArray - 1],
                        e.target.files[qtdArray - 1].type === ''
                          ? ext[ext.length - 1]
                          : e.target.files[qtdArray - 1].type
                      );
                    }}
                  />
                </label>
              </S.ActionCard>
            </S.ActionCardContainer>
          </>
        )}
      </S.Container>
      {dashboardState === 'dashboard@fields' && (
        <ConfirmDialog
          messageDialog={`Deseja Excluir o Talhão ${deleteField?.features[0]?.properties?.field_name} ?`}
          alert="ATENÇÃO: Ao deletar uma fazenda todas as subdivisões, caminhamentos e pontos de coleta associadas ao mesmo seram apagados."
          field={deleteField}
        />
      )}
    </FieldContext.Provider>
  );
};

export default Field;
