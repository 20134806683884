/* eslint-disable prefer-template */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdChevronRight, MdDelete, MdModeEdit } from 'react-icons/md';
import Button from '../../../../components/Button';
import { useAuth } from '../../../../hooks/Auth';
import { useMap } from '../../../../hooks/Map';
import api from '../../../../services/api';
import { DashboardContext } from '../../index';
import Toast from '../../../../components/Toast';
import * as S from './styled';
import { appConfig } from '../../../../config';

const ResultsMap = () => {
  const { setDashboardState, setResultVisible, setSubdivisions, setRecommendationVisible } = useContext(DashboardContext);
  const {
    editRecommendationZone,
    selectedFarmId,
    setRecommZoneList,
    recommZoneList,
    removeRecommendationZone,
    renderRecommendationZone,
    setSelectedRecommZone,
    renderSubdivisions,
    setListSubdivisions,
    setRecommendationZoneId,
    removeColoredRecommendationZone,
    renderRecommendationZones,
    updateSizeMap,
    setTableResults,
    tableResults,
    recommDepth,
    setRecommDepth,
    recommFertilizer,
    setRecommFertilizer,
    recommName,
    setRecommName,
    recommType,
    setRecommType,
    recommMeasurementUnit,
    setRecommMeasurementUnit,
    renderSubdivisionsNoLabel,
    setLegendRecommendationValue,
    setSelectedSubdivisionsForReccomendationZone,
    selectedSubdivisionsForReccomendationZone
  } = useMap();
  const { token, user } = useAuth();

  const [generationButtonIsDisable, setGenerationButtonIsDisable] = useState(true);

  const getSubdivisions = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      // renderSubdivisions(response.data.data);
      renderSubdivisionsNoLabel(response.data.data);
      setListSubdivisions(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getRecommZoneList = async () => {
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que as sejam carregadas as listas de zona de recomendascoes é necessario selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }

      const params = new URLSearchParams([['farmId', selectedFarmId], ['userId', user.user_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationZoneListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setRecommZoneList(response.data.data);
      renderRecommendationZones(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getRecommZone = async (id) => {
    try {
      const params = new URLSearchParams([['RecommendationZoneId', id]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationZoneById`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSelectedRecommZone(response.data.data);
      renderRecommendationZone(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getReport = async () => {
    try {
      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetReportListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setTableResults(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const editRecommZoneDrawed = async (id) => {
    setSelectedSubdivisionsForReccomendationZone([]);
    try {
      const params = new URLSearchParams([['RecommendationZoneId', id]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationZoneById`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // removeRecommendationZone();
      setSelectedRecommZone(response.data.data);
      editRecommendationZone(response.data.data);
      setDashboardState('dashboard@edit_recommendationZone');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const deleteRecommendationZone = async (id) => {
    removeRecommendationZone();
    try {
      const params = new URLSearchParams([
        ['RecommendationZoneId', id],
        ['status', 'false']]);
      const response = await api.delete(
        `${appConfig.apiHosts.report}/RecommendationZone/DeleteRecommendationZone`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      getRecommZoneList();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    getSubdivisions();
    getRecommZoneList();
    removeRecommendationZone();
    setResultVisible(false);
    removeColoredRecommendationZone();
    setRecommendationVisible(false);
    updateSizeMap();
    getReport();
    setLegendRecommendationValue([]);
    setSelectedSubdivisionsForReccomendationZone([]);
  }, []);

  useEffect(() => {
    const list = recommZoneList?.filter((value) => value.features[0].properties.status_recommendation === 'RECOMENDACAO_REALIZADA')[0];
    if (list !== undefined) {
      setGenerationButtonIsDisable(false);
    }
    if (list === undefined) {
      setGenerationButtonIsDisable(true);
    }
  }, [recommZoneList]);

  return (
    <S.Container>
      <h1>Mapa de resultados</h1>
      <hr />
      <p>
        Desenhe o polígono no mapa ao lado referente a área desejada para criar
        a recomendação.
      </p>
      <S.Content>
        <div className="cardContainer">
          {recommZoneList?.map((zone, index) => {
            return (
              <S.ZoneCardContainer
                key={zone.features[0].properties.recommendation_zone_id}
              >
                <S.ZoneCardHeader />
                <S.ZoneCardText onClick={() => {
                  getRecommZone(zone.features[0].properties.recommendation_zone_id);
                }}
                >
                  <h3>Zona {index + 1}</h3>
                  <MdChevronRight size={22} />
                </S.ZoneCardText>
                <S.ZoneCardActions>
                  <button
                    type="button"
                    onClick={() => {
                      editRecommZoneDrawed(zone.features[0].properties.recommendation_zone_id);
                    }}
                  >
                    <span>Editar</span>
                    <MdModeEdit />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      deleteRecommendationZone(zone.features[0].properties.recommendation_zone_id);
                    }}
                  >
                    <span>Deletar</span>
                    <MdDelete />
                  </button>
                </S.ZoneCardActions>
                {zone.features[0].properties.status_recommendation === 'RECOMENDACAO_REALIZADA' ? (
                  <S.RecommendationButton className="recommMade">
                    Recomendação realizada <FaRegCheckCircle size={20} style={{ marginLeft: '10px' }} />
                  </S.RecommendationButton>
                ) : (
                  <S.RecommendationButton
                    onClick={() => {
                      getRecommZone(zone.features[0].properties.recommendation_zone_id);
                      setRecommendationZoneId(zone.features[0].properties.recommendation_zone_id);
                      setSelectedRecommZone(zone);
                      const name = (index + 1).toString();
                      const zoneName = 'Zona ' + name;
                      setRecommName(zoneName);
                      setDashboardState('dashboard@selectParametersRecommendation');
                    }}
                  >
                    Realizar recomendação
                  </S.RecommendationButton>
                )}
              </S.ZoneCardContainer>
            );
          })}
        </div>
        <S.ButtonContainer>
          <Button text="Desenhar zona" onClick={() => setDashboardState('dashboard@drawedRecommendationZone')} disabled={!selectedFarmId} />
        </S.ButtonContainer>
        <S.ButtonContainer>
          <Button
            text="Gerar mapa de recomendação"
            disabled={generationButtonIsDisable}
            onClick={() => {
              setRecommendationVisible(true);
              setDashboardState('dashboard@recommendationMap');
            }}
          />
        </S.ButtonContainer>
      </S.Content>
    </S.Container>
  );
};

export default ResultsMap;
