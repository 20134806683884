import Control from 'ol/control/Control';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  HiOutlineChevronDoubleDown,
  HiOutlineChevronDoubleUp
} from 'react-icons/hi';
import {
  MdCloud,
  MdCloudUpload,
  MdFilterList,
  MdFlip,
  MdForum,
  MdImage,
  MdVerticalAlignBottom,
  MdVerticalAlignTop
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/Auth';
import { useMap } from '../../hooks/Map';
import ImgLegendaP from '../../assets/legenda_p.png';
import ImgLegendaMo from '../../assets/legenda_mo.png';
import { DashboardContext } from '../../pages/Dashboard';
import api from '../../services/api';
import Chat from '../Chat';
import Toast from '../Toast';
import ResultReport from '../../pages/Dashboard/components/Results/components/ResultReport';
import {
  ButtonExibition,
  ButtonExibitionContainer,
  CardImportFile,
  Container,
  Controls,
  LegendRecommBody,
  LegendRecommContainer,
  LegendRecommValueColor,
  LegendRecommValueContainer,
  LegendResultsBody,
  LegendResultsContainer,
  LegendResultsDescription,
  LegendResultsHeader,
  LegendResultsValueColor,
  LegendResultsValueContainer,
  ContainerLegend,
  TableResultsContainer
} from './styled';
import { appConfig } from '../../config';

const WebMap: React.FC = ({ ...props }) => {
  const [map, setMap] = useState();
  const mapRef = useRef();
  const controlRef = useRef();
  const legendRef = useRef();
  const { user, token } = useAuth();
  mapRef.current = map;
  const {
    newMap,
    setRefMap,
    refMap,
    controlState,
    setControlState,
    addSwipe,
    legendResultsValue,
    selectedFarmGeoJson,
    raster,
    maptiler,
    btnColor,
    setBtnColor,
    selectedFarmId,
    showChat,
    setShowChat,
    showButtonChat,
    renderFarm,
    swipeShow,
    setHideNav,
    legendRecommendationValue,
    parameterResults,
    updateSizeMap,
    setOffSetHeigth,
    orientation,
    getHorizontalLengthOfGeometry,
    setLegendRecommendationValue
  } = useMap();
  const {
    dashboardState,
    showResult,
    setShowResult,
    resultVisible,
    hideCarouselOptions,
    recommendationVisible,
    chatNotifications
  } = useContext(DashboardContext);

  const mapLayer = maptiler;

  const [minimizedLegend, setMinimizedLegend] = useState(false);

  useEffect(() => {
    const originMap = newMap(mapRef.current, mapLayer);
    setRefMap(originMap);
    const myControl = new Control({ element: controlRef.current });
    originMap.addControl(myControl);
    const myLegend = new Control({ element: legendRef.current });
    originMap.addControl(myLegend);
    setOffSetHeigth(mapRef.current.offsetHeight);
  }, []);

  const showReport = () => {
    setShowResult(!showResult);
  };

  const sendRelatory = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.post(
        `${appConfig.apiHosts.report}/Report/Import`,
        formData,
        {
          params,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="Relátorio importado com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(
        <Toast
          title="Relátorio não foi importado"
          content="Verifique se o arquivo se encontra no padrão correto"
        />,
        { type: 'error' }
      );
    }
  };

  const handleUploadFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState !== 2 || reader.error) {
        return null;
      }
      sendRelatory(file);
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (dashboardState === 'dashboard@report') {
      updateSizeMap();
    }
  }, [dashboardState]);

  return (
    <>
      <Controls ref={controlRef} className="buttonMap">
        {dashboardState !== 'dashboard@results' &&
          dashboardState !== 'dashboard@resultsMap' &&
          dashboardState !== 'dashboard@report' &&
          dashboardState !== 'dashboard@report' &&
          dashboardState !== 'dashboard@createdReports' && (
            <>
              {!hideCarouselOptions && (
                <button
                  type="button"
                  onClick={() => {
                    if (
                      controlState === undefined ||
                      controlState === 'field-filter' ||
                      controlState === 'upload-shapefile' ||
                      controlState === 'satellite'
                    ) {
                      setControlState('cloud-filter');
                    } else {
                      setControlState(undefined);
                    }
                  }}
                  className={
                    controlState === 'cloud-filter' ? 'buttonActived' : ''
                  }
                >
                  <MdCloud />
                </button>
              )}
              {dashboardState === 'dashboard@fields' && (
                <button
                  type="button"
                  onClick={() => {
                    if (
                      controlState === undefined ||
                      controlState === 'cloud-filter' ||
                      controlState === 'upload-shapefile' ||
                      controlState === 'satellite'
                    ) {
                      setControlState('field-filter');
                    } else {
                      setControlState(undefined);
                    }
                  }}
                  className={
                    controlState === 'field-filter' ? 'buttonActived' : ''
                  }
                >
                  <MdFilterList />
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  if (
                    controlState === undefined ||
                    controlState === 'cloud-filter' ||
                    controlState === 'field-filter' ||
                    controlState === 'satellite'
                  ) {
                    setControlState('upload-shapefile');
                  } else {
                    setControlState(undefined);
                  }
                }}
                className={
                  controlState === 'upload-shapefile' ? 'buttonActived' : ''
                }
              >
                <MdCloudUpload />
              </button>
              {!hideCarouselOptions && (
                <button
                  type="button"
                  onClick={() => {
                    if (
                      controlState === undefined ||
                      controlState === 'cloud-filter' ||
                      controlState === 'field-filter' ||
                      controlState === 'upload-shapefile'
                    ) {
                      setControlState('satellite');
                    } else {
                      setControlState(undefined);
                    }
                  }}
                  className={
                    controlState === 'satellite' ? 'buttonActived' : ''
                  }
                >
                  <MdImage />
                </button>
              )}
            </>
          )}
        {dashboardState === 'dashboard@results' && (
          <button
            disabled={!swipeShow}
            type="button"
            onClick={() => {
              addSwipe(selectedFarmGeoJson);
              setBtnColor(!btnColor);
            }}
            className={`${btnColor}`}
          >
            <MdFlip />
          </button>
        )}
        {dashboardState !== 'dashboard@report' && (
          <button
            type="button"
            onClick={() => {
              setShowChat(!showChat);
            }}
            className={chatNotifications.length > 0 ? 'newMsg' : ''}
          >
            <MdForum />
          </button>
        )}
      </Controls>
      <ContainerLegend ref={legendRef}>
        {legendResultsValue.length > 0 &&
          resultVisible &&
          dashboardState === 'dashboard@results' && (
            <>
              {parameterResults !== 'P' && parameterResults !== 'MO x Arg' && (
                <LegendResultsContainer>
                  <LegendResultsHeader>
                    <h3>
                      Parâmetro: <b>{parameterResults}</b>
                    </h3>
                    <button
                      type="button"
                      onClick={() => setMinimizedLegend(!minimizedLegend)}
                    >
                      {minimizedLegend ? (
                        <MdVerticalAlignTop />
                      ) : (
                        <MdVerticalAlignBottom />
                      )}
                    </button>
                  </LegendResultsHeader>
                  <LegendResultsBody
                    minimized={minimizedLegend}
                    className="legendDynamics"
                  >
                    {legendResultsValue.map((legendResult, index) => {
                      return (
                        <LegendResultsValueContainer key={index}>
                          <LegendResultsValueColor
                            colored={legendResult.color}
                          />
                          <LegendResultsDescription>
                            {`${legendResult.range} - ${legendResult.description}`}
                          </LegendResultsDescription>
                        </LegendResultsValueContainer>
                      );
                    })}
                  </LegendResultsBody>
                </LegendResultsContainer>
              )}

              {parameterResults === 'P' && (
                <LegendResultsContainer className="legendImgP">
                  <LegendResultsHeader>
                    <h3>
                      Parâmetro: <b>{parameterResults}</b>
                    </h3>
                    <button
                      type="button"
                      onClick={() => setMinimizedLegend(!minimizedLegend)}
                    >
                      {minimizedLegend ? (
                        <MdVerticalAlignTop />
                      ) : (
                        <MdVerticalAlignBottom />
                      )}
                    </button>
                  </LegendResultsHeader>
                  <LegendResultsBody minimized={minimizedLegend}>
                    <LegendResultsValueContainer>
                      <img alt="legendaP" src={ImgLegendaP} />
                    </LegendResultsValueContainer>
                  </LegendResultsBody>
                </LegendResultsContainer>
              )}

              {parameterResults === 'MO x Arg' && (
                <LegendResultsContainer className="legendImgMo">
                  <LegendResultsHeader>
                    <h3>
                      Parâmetro: <b>{parameterResults}</b>
                    </h3>
                    <button
                      type="button"
                      onClick={() => setMinimizedLegend(!minimizedLegend)}
                    >
                      {minimizedLegend ? (
                        <MdVerticalAlignTop />
                      ) : (
                        <MdVerticalAlignBottom />
                      )}
                    </button>
                  </LegendResultsHeader>
                  <LegendResultsBody minimized={minimizedLegend}>
                    <LegendResultsValueContainer>
                      <img alt="legendaMo" src={ImgLegendaMo} />
                    </LegendResultsValueContainer>
                  </LegendResultsBody>
                </LegendResultsContainer>
              )}
            </>
          )}

        <TableResultsContainer show={showResult}>
          {legendResultsValue.length > 0 &&
            resultVisible &&
            dashboardState === 'dashboard@results' && (
              <ButtonExibitionContainer
                className="tableResult"
                onClick={() => showReport()}
              >
                {showResult ? (
                  <ButtonExibition>
                    <HiOutlineChevronDoubleDown size={25} />
                  </ButtonExibition>
                ) : (
                  <ButtonExibition>
                    <HiOutlineChevronDoubleUp size={25} />
                  </ButtonExibition>
                )}
              </ButtonExibitionContainer>
            )}
          {showResult && (
            <div className="resultContainer">
              <ResultReport />
            </div>
          )}
        </TableResultsContainer>

        {showChat && <Chat />}
        {dashboardState === 'dashboard@harvestmap' && (
          <CardImportFile>
            <label htmlFor="upload-file-farm" className="label-upload">
              <MdCloudUpload size={22} color="#9FBC38" />
              <div>
                <span>Fazer o upload de relatório</span>
                <br />
                <span>Formatos aceitos: .xlsx ou .xls</span>
              </div>
              <input
                type="file"
                accept=".xlsx, .xls"
                id="upload-file-farm"
                multiple={false}
                onChange={(e) => {
                  const qtdArray = e?.target?.files?.length;
                  if (
                    e?.target?.files[qtdArray - 1]?.type !==
                      'application/vnd.ms-excel' &&
                    e?.target?.files[qtdArray - 1]?.type !==
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
                    e?.target?.files[qtdArray - 1]?.type !== 'application/xls'
                  ) {
                    return (
                      null,
                      toast(
                        <Toast
                          title="Shapefile no formato incorreto"
                          content="Formatos aceitos: .xlsx ou .xls "
                        />,
                        {
                          type: 'error'
                        }
                      )
                    );
                  }
                  handleUploadFile(e?.target?.files[qtdArray - 1]);
                }}
              />
            </label>
          </CardImportFile>
        )}
        {legendRecommendationValue.length > 0 && (
          <LegendRecommContainer>
            <LegendResultsHeader>
              <h3>Legenda</h3>
            </LegendResultsHeader>
            <LegendRecommBody minimized={minimizedLegend}>
              {legendRecommendationValue.map((legendRecommendation, index) => {
                return (
                  <LegendRecommValueContainer key={index}>
                    <LegendRecommValueColor
                      colored={legendRecommendation.color}
                    />
                    <LegendResultsDescription>
                      {`${legendRecommendation.quantity} ${legendRecommendation.measurement_unit} - ${legendRecommendation.fertilize}`}
                    </LegendResultsDescription>
                  </LegendRecommValueContainer>
                );
              })}
            </LegendRecommBody>
          </LegendRecommContainer>
        )}
      </ContainerLegend>
      <Container
        ref={mapRef}
        className="WebMap"
        {...props}
        ReportOrientation={orientation}
        isReport={dashboardState === 'dashboard@report'}
      />
    </>
  );
};

export default WebMap;
