/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-modal';
import XLSX from 'xlsx';
import {
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Paper,
  TableContainer
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useModal } from '../../../../../../../../../hooks/Modal';
import { DashboardContext } from '../../../../../../../index';
import { useAuth } from '../../../../../../../../../hooks/Auth';
import api from '../../../../../../../../../services/api';
import { appConfig } from '../../../../../../../../../config';
import Button from '../../../../../../../../../components/Button';
import * as S from './styled';

const ModaExportRoutine = () => {
  const { closeModal, modalIsOpen } = useModal();
  const { token } = useAuth();
  const { selectedMonitoring } = useContext(DashboardContext);

  const [monitoringReports, setMonitoringReports] = useState([]);

  const formatDate = (date, symbol = '/') => {
    const data = new Date(date);
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}${symbol}${mes}${symbol}${ano}`;
  };

  const sortArr = (data, field) => {
    return data.sort((a, b) => {
      const fieldA = a[field];
      const fieldB = b[field];
      if (fieldA < fieldB) {
        return -1;
      }
      if (fieldA > fieldB) {
        return 1;
      }
      return 0;
    });
  };

  const getHistoriesRoutine = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.prague}/Routine/HistoriesByRoutine?routine_id=${selectedMonitoring.routine_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const newArr = sortArr(response.data.data.historieRoutines, 'parameters');
      sortArr(newArr, 'point');

      const groupedData = newArr.reduce((acc, obj) => {
        const key = `${obj.parameters}-${obj.species}-${obj.point}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        obj.value = Number(obj.value) || 0;
        obj.point = Number(obj.point) || 0;
        acc[key].push(obj);
        return acc;
      }, {});

      const groupedArray = Object.values(groupedData);

      const newGroupedArray = groupedArray.map((items) => {
        const sum = items.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
        const average = sum / items.length || 0;

        items.forEach((item) => {
          item.average = average;
        });

        return items;
      });

      setMonitoringReports(newGroupedArray);
    } catch (error) {
      console.error(error);
    }
  };

  const getHours = (dataString) => {
    const formatedDate = new Date(dataString);

    const hours = formatedDate.toLocaleTimeString('pt-BR', { hour12: false });
    return hours;
  };

  const xport = useCallback(() => {
    const table = document.getElementById('Table2XLSX');
    const wb = XLSX.utils.table_to_book(table);

    XLSX.writeFile(wb, `monitoramento_de_campo_${formatDate(new Date(), '_')}.xlsx`);
  });

  useEffect(() => {
    getHistoriesRoutine();
  }, [selectedMonitoring]);

  const styles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      width: '600px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: '800px',
      maxHeight: '85%'
    }
  };

  return (
    <Modal
      aria={{
        labelledby: 'heading',
        describedby: 'full_description'
      }}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={styles}
    >
      <S.Container>
        <div style={{ padding: '25px' }}>
          <button
            type="button"
            className="table__addButton"
            onClick={() => closeModal()}
          >
            <ArrowBack />
          </button>
          <S.TableTitle>RELATÓRIO DE MONITORAMENTO DE CAMPO</S.TableTitle>
          <S.TableHeader>
            <S.TableWrapper style={{ margin: 0 }}>
              <S.TableContainer>
                <S.SubTitle>
                  Titulo:
                </S.SubTitle>
                <S.TextContainer>{selectedMonitoring?.title}</S.TextContainer>
              </S.TableContainer>
              <S.TableContainer>
                <S.SubTitle>
                  Data Final:
                </S.SubTitle>
                <S.TextContainer>{formatDate(selectedMonitoring?.end_date)}</S.TextContainer>
              </S.TableContainer>
            </S.TableWrapper>
            <S.TableWrapper style={{ margin: 0 }}>
              <S.TableContainer>
                <S.SubTitle>
                  Técnico:
                </S.SubTitle>
                <S.TextContainer>{selectedMonitoring?.executor_name}</S.TextContainer>
              </S.TableContainer>
              <S.TableContainer>
                <S.SubTitle>
                  Talhão:
                </S.SubTitle>
                <S.TextContainer>{selectedMonitoring?.encompass_name}</S.TextContainer>
              </S.TableContainer>
            </S.TableWrapper>
            <S.TableWrapper style={{ margin: 0 }}>
              <S.TableContainer>
                <S.SubTitle>
                  Data Inicial:
                </S.SubTitle>
                <S.TextContainer>{formatDate(selectedMonitoring?.init_date)}</S.TextContainer>
              </S.TableContainer>
              <S.TableContainer>
                <S.SubTitle>
                  Horário:
                </S.SubTitle>
                <S.TextContainer>{getHours(selectedMonitoring?.init_date)}</S.TextContainer>
              </S.TableContainer>
            </S.TableWrapper>
          </S.TableHeader>
        </div>
      </S.Container>
      <TableContainer>
        <Table size="small" cellspacing="0" id="Table2XLSX">
          <TableHead>
            <TableRow style={{ backgroundColor: '#EFEFEF', fontWeight: 'bold' }}>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Ponto</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Parâmetro</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Espécie</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Valor</TableCell>
              <TableCell colSpan={3} scope="colgroup" align="center" style={{ fontWeight: 'bold' }}>Média</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {monitoringReports && monitoringReports.map((monitoringReport) => (
              <>
                {monitoringReport.map((report, index) => (
                  <TableRow>
                    {index === 0 && <TableCell rowSpan={monitoringReport.length} scope="rowgroup" align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{report.point}</TableCell>}
                    {index === 0 && <TableCell rowSpan={monitoringReport.length} scope="rowgroup" align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{report.parameters}</TableCell>}
                    {index === 0 && <TableCell rowSpan={monitoringReport.length} scope="rowgroup" align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{report.species}</TableCell>}
                    <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{report.value}</TableCell>
                    {index === 0 && <TableCell rowSpan={monitoringReport.length} scope="rowgroup" align="center">{report.average.toFixed(2)}</TableCell>}
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <S.ExportButton>
        <button
          type="button"
          className="exportButton"
          onClick={xport}
        >
          EXPORTAR RESULTADOS
        </button>
      </S.ExportButton>
    </Modal>
  );
};

export default ModaExportRoutine;
