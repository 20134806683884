import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  MdPerson,
  MdNotifications,
  MdArrowDropDown,
  MdOutlineMenuBook
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../services/api';
import Button from '../Button';
import { useAuth } from '../../hooks/Auth';
import { useMap } from '../../hooks/Map';
import { DashboardContext } from '../../pages/Dashboard/index';
import {
  Container,
  Navigation,
  Notifications,
  UserOptions,
  NotificationOptions,
  HelpButton,
  DropDownNotification
} from './styled';
import logo from '../../assets/MARCA_FUNDACAO_MT_versao_horizontal_colorida.png';
import NotificationCard from '../NotificationCard';
import Toast from '../Toast';
import { appConfig } from '../../config';
import alertSound from '../../assets/alert_msg.mp3';
import { dashboardStates } from '../../pages/Dashboard/states';
import serviceNotification from '../../services/notification';

const Header = ({ fullname }) => {
  const { signOut, getNotifications, user, token } = useAuth();
  const {
    setSelectedFarmId,
    setSelectedFarmGeoJson,
    farmName,
    setFarmName,
    disableQueryNotification,
    setDisableQueryNotification
  } = useMap();
  const [userOptionsState, setUserOptionsState] = useState(false);
  const [notificationOptionsState, setNotificationOptionsState] =
    useState(false);
  const [enableNotificationQuery, setEnable] = useState(true);
  const refUserOptions = useRef();
  const handleOpenUserOptions = () => setUserOptionsState(!userOptionsState);
  const handleOpenNotification = () =>
    setNotificationOptionsState(!notificationOptionsState);
  const handleClickOutside = (event) => {
    return refUserOptions.current &&
      !refUserOptions.current.contains(event.target)
      ? setUserOptionsState(false)
      : null;
  };

  const {
    setNotifications,
    notifications,
    chatNotifications,
    setChatNotifications
  } = useContext(DashboardContext);

  const handleReadNotification = async (notification) => {
    try {
      await serviceNotification.updateNotification(notification, token);
      getNotifications(user.email, token).then((v) =>
        setNotifications(v.notifications)
      );
    } catch (error) {
      toast(
        <Toast title="Não foi possivel executar a marcação de leitura da notificação" />,
        {
          type: 'error'
        }
      );
      console.error(error);
    }
  };

  const handleDeleteNotification = async (notificationId) => {
    try {
      await serviceNotification.deleteNotification(notificationId, token);
      getNotifications(user.email, token).then((v) =>
        setNotifications(v.notifications)
      );
      toast(<Toast title="Notificação excluida com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(<Toast title="Não foi possivel excluir a notificação" />, {
        type: 'error'
      });
      console.error(error);
    }
  };

  useEffect(() => {
    getNotifications(user.email, token).then((response) => {
      setNotifications(response.notifications);
      setChatNotifications(response.chatNotifications);
    });
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.addEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const messageChatNotification = (quantityMsg, name) => {
    if (quantityMsg > 1) {
      return `Você possui ${quantityMsg} Mensagens do Usuario ${name}`;
    }
    return `Você possui ${quantityMsg} Mensagem do Usuario ${name}`;
  };

  const notificationsTextBadge = () => {
    if (notifications !== null || notifications !== undefined) {
      const countNotifications = notifications.filter(
        (value) => value.read === false
      ).length;
      return countNotifications + chatNotifications.length;
    }
    return '';
  };

  const queryClientNotifications = useQueryClient();

  const { status, data, isFetching, enabled } = useQuery(
    'syncNotifications',
    async () => {
      await getNotifications(user.email, token).then((response) => {
        setNotifications(response.notifications);
        setChatNotifications(response.chatNotifications);
        if (chatNotifications.length < response.chatNotifications.length) {
          const audio = new Audio(alertSound);
          audio.play();
        }
      });
    },
    {
      enabled: disableQueryNotification,
      refetchInterval: appConfig.verifyTimeNotifications
    }
  );

  // const addMutation = useMutation(
  //   (email, accesstoken) =>
  //     getNotifications(email, accesstoken).then((response) => {
  //       setNotifications(response.notifications);
  //       setChatNotifications(response.chatNotifications);
  //     }),
  //   {
  //     onSuccess: () =>
  //       queryClientNotifications.invalidateQueries('syncNotifications')
  //   }
  // );

  // const clearMutation = useMutation(
  //   (email, accesstoken) =>
  //     getNotifications(email, accesstoken).then((response) => {
  //       setNotifications(response.notifications);
  //       setChatNotifications(response.chatNotifications);
  //     }),
  //   {
  //     onSuccess: () =>
  //       queryClientNotifications.invalidateQueries('syncNotifications')
  //   }
  // );

  return (
    <Container>
      <div>
        <img src={logo} alt="Fundação MT" />
      </div>
      <Navigation>
        {farmName !== '' && (
          <span>
            <b>Fazenda</b>: {farmName}
          </span>
        )}
        <NotificationOptions>
          <Notifications>
            <button type="button" onClick={handleOpenNotification}>
              <div>
                {notificationsTextBadge() > 0 && (
                  <span>{notificationsTextBadge()}</span>
                )}

                <MdNotifications />
              </div>
              <span>Notificações</span>
              <MdArrowDropDown />
            </button>
            {notificationOptionsState && (
              <div className="dropdown">
                <DropDownNotification>
                  {chatNotifications?.map((notificationChat) => {
                    return (
                      <li key={notificationChat.chat_id}>
                        <NotificationCard read={false} actions={false}>
                          {messageChatNotification(
                            notificationChat.quantityUnreadMessage,
                            notificationChat.userOrigin_name
                          )}
                        </NotificationCard>
                      </li>
                    );
                  })}
                  {notifications?.map((notification) => {
                    return (
                      <li key={notification.notification_id}>
                        <NotificationCard
                          read={notification.read}
                          onClickMarkRead={() => {
                            handleReadNotification(notification);
                          }}
                          onClickDelete={() => {
                            handleDeleteNotification(
                              notification.notification_id
                            );
                          }}
                        >
                          {notification.notification_message}
                        </NotificationCard>
                      </li>
                    );
                  })}

                  {notifications.length === 0 &&
                    chatNotifications.length ===
                    (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          alignContent: 'space-between',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%'
                        }}
                      >
                        <MdNotifications color="#DCEBA9" size={100} />
                        Você não tem notificações
                      </div>
                    )}
                </DropDownNotification>
              </div>
            )}
          </Notifications>
        </NotificationOptions>

        <UserOptions ref={refUserOptions}>
          <button type="button" onClick={handleOpenUserOptions}>
            <div>
              <MdPerson size={18} />
            </div>
            <span>{fullname}</span>
            <MdArrowDropDown />
          </button>
          {userOptionsState && (
            <div className="dropdown">
              <ul>
                <li>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedFarmId('');
                      setSelectedFarmGeoJson('');
                      setFarmName('');
                      setDisableQueryNotification(false);
                      signOut();
                    }}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </UserOptions>
      </Navigation>
    </Container>
  );
};

Header.propTypes = {
  fullname: PropTypes.string.isRequired
};

export default Header;
