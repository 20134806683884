import React from 'react';
import {
  MdAssessment,
  MdHome,
  MdInfo,
  MdMap,
  MdSpa,
  MdPictureAsPdf,
  MdAdminPanelSettings,
  MdBugReport,
  MdMenuBook
} from 'react-icons/md';
import Farm from './components/Farm';
import RegisterFarm from './components/Farm/components/RegisterFarm';
import EditingFarm from './components/Farm/components/EditingFarm';
import Field from './components/Farm/components/Field';
import EditingField from './components/Farm/components/Field/components/EditingField';
import RegisterFieldFromShapefile from './components/Farm/components/Field/components/RegisterFieldFromShapefile';
import RegisterFarmFromShapefile from './components/Farm/components/RegisterFarmFromShapefile';
import HarvestMap from './components/HarvestMap';
import RegisterSubdivision from './components/HarvestMap/components/RegisterSubdivision';
import EditSubdivision from './components/HarvestMap/components/EditSubdivision';
import Information from './components/Information';
import ResultsMap from './components/ResultsMap';
import Results from './components/Results';
import EditingMapping from './components/Information/components/EditingMapping';
import RegisterWalkSubdivision from './components/HarvestMap/components/RegisterWalkSubdivision';
import EditWalkOfSubdivision from './components/HarvestMap/components/EditWalkOfSubdivision';
import RegisterMapping from './components/Information/components/RegisterMapping';
import SendLaboratory from './components/HarvestMap/components/SendLaboratory';
import GenerateCollectionPoints from './components/HarvestMap/components/GenerateCollectionPoints';
import FinnalyWalkSubdivision from './components/HarvestMap/components/FinnalyWalkSubdivision';
import SelectedMaps from './components/Results/components/SelectedMaps';
import MakeRecommendation from './components/ResultsMap/Components/MakeRecommendation';
import RecommendationMap from './components/ResultsMap/Components/RecommendationMap';
import DrawRecommendationZone from './components/ResultsMap/Components/DrawRecommendationZone';
import EditRecommendationZone from './components/ResultsMap/Components/EditRecommendationZone';
import SelectParametersRecommendation from './components/ResultsMap/Components/SelectParametersRecommendation';
import Report from './components/Report';
import CreatedReports from './components/Report/components/CreatedReports';
import DataManagement from './components/DataManagement';
import PestMonitoring from './components/PestMonitoring';
import KnowledgeBase from './components/KnowledgeBase';

export const dashboardStates = [
  {
    id: 1,
    title: 'Dashboard',
    path: 'dashboard@farm',
    component: <Farm />,
    navIcon: <MdHome />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 2,
    title: 'Levantamento de Informações',
    path: 'dashboard@information',
    component: <Information />,
    navIcon: <MdInfo />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 3,
    title: 'Mapa de Coleta',
    path: 'dashboard@harvestmap',
    component: <HarvestMap />,
    navIcon: <MdMap />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 4,
    title: 'Resultados de Análise',
    path: 'dashboard@results',
    component: <Results />,
    navIcon: <MdSpa />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 5,
    title: 'Mapa de Resultados',
    path: 'dashboard@resultsMap',
    component: <ResultsMap />,
    navIcon: <MdAssessment />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 6,
    title: 'Adicionar Fazenda',
    path: 'dashboard@registerFarm',
    component: <RegisterFarm />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 7,
    title: 'Editando Fazenda',
    path: 'dashboard@editFarm_step1',
    component: <EditingFarm />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 8,
    title: 'Cadastro de Talhão',
    path: 'dashboard@fields',
    component: <Field />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 9,
    title: 'Editando Talhão',
    path: 'dashboard@editField',
    component: <EditingField />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 10,
    title: 'Adicionar Fazenda do Shapefile',
    path: 'dashboard@registerFarm_shapefile',
    component: <RegisterFarmFromShapefile />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 11,
    title: 'Adicionar Talhão do Shapefile',
    path: 'dashboard@registerField_shapefile',
    component: <RegisterFieldFromShapefile />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 12,
    title: 'Editar Caminhamento',
    path: 'dashboard@edit_mapping',
    component: <EditingMapping />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 13,
    title: 'Editar Caminhamento',
    path: 'dashboard@register_subdivision',
    component: <RegisterSubdivision />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 14,
    title: 'Editar Subdivisão',
    path: 'dashboard@edit_subdivision',
    component: <EditSubdivision />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 15,
    title: 'Criar Caminhamento de Subdivisão',
    path: 'dashboard@register_walk_subdivision',
    component: <RegisterWalkSubdivision />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 16,
    title: 'Editar Caminhamento de Subdivisão',
    path: 'dashboard@edit_walk_subdivision',
    component: <EditWalkOfSubdivision />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 17,
    title: 'Criar Caminhamento',
    path: 'dashboard@register_walk',
    component: <RegisterMapping />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 18,
    title: 'Enviar para o Laboratorio',
    path: 'dashboard@send_laboratory',
    component: <SendLaboratory />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 19,
    title: 'Gerar pontos de coleta',
    path: 'dashboard@generateCollectionPoints',
    component: <GenerateCollectionPoints />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 20,
    title: 'Finalizar Multi Caminahmento de Subdivisão',
    path: 'dashboard@finnalyMappingSubdivision',
    component: <FinnalyWalkSubdivision />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 21,
    title: 'Visualização da lista de Mapas Renderizados',
    path: 'dashboard@renderedMaps',
    component: <SelectedMaps />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 22,
    title: 'Fazer Recomendação',
    path: 'dashboard@makeRecommendation',
    component: <MakeRecommendation />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 23,
    title: 'Mapa de Recomendação',
    path: 'dashboard@recommendationMap',
    component: <RecommendationMap />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 24,
    title: 'Desenhar Zona de Recomendação',
    path: 'dashboard@drawedRecommendationZone',
    component: <DrawRecommendationZone />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 25,
    title: 'Editar Zona de Recomendação',
    path: 'dashboard@edit_recommendationZone',
    component: <EditRecommendationZone />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 26,
    title: 'Report',
    path: 'dashboard@report',
    component: <Report />,
    navIcon: <MdPictureAsPdf />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 26,
    title: 'Relatórios Criados',
    path: 'dashboard@createdReports',
    component: <CreatedReports />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 27,
    title: 'Parametros de Recomendação',
    path: 'dashboard@selectParametersRecommendation',
    component: <SelectParametersRecommendation />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 28,
    title: 'Administração de Dados',
    path: 'dashboard@dataManagement',
    component: <DataManagement />,
    navIcon: <MdAdminPanelSettings />,
    roles: ['ADM']
  },
  {
    id: 29,
    title: 'Monitoramento de Pragas',
    path: 'dashboard@pestMonitoring',
    component: <PestMonitoring />,
    navIcon: <MdBugReport />,
    roles: ['ADM', 'PADRAO']
  },
  {
    id: 30,
    title: 'Base de Conhecimento',
    path: 'dashboard@knowledgeBase',
    component: <KnowledgeBase />,
    navIcon: <MdMenuBook />,
    roles: ['ADM', 'PADRAO']
  }
];
