import React, { ButtonHTMLAttributes, ReactElement } from 'react';
import { Container } from './styled';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: ReactElement;
}

const Button: React.FC<IButton> = ({ text, ...props }) => {
  return <Container {...props}>{text}</Container>;
};

export default Button;
