import styled from 'styled-components';
import {
  Table,
  Select as MUISelect,
  TextField,
  Button as MUIButton
} from '@mui/material';

export const Container = styled.div`
  height: 100%;
  width: 100%;

  table {
    border-collapse: separate;
    border: 1px solid #dfdfdf;
    border-bottom: none;
    border-radius: 8px !important;
  }

  td,
  th {
    border-left: 1px solid #dfdfdf;
  }

  .selectStyle {
    width: 320px;
    font-size: 16px;
    margin-bottom: 16px;
  }

  hr {
    border: 1px solid #444;
  }

  .nav__hr {
    border: 1px solid #a0bd39;
    margin-bottom: 5px;
  }

  /* .MuiTable-root {
    border-collapse: separate;
    //border: 1px solid #dfdfdf;
    border-radius: '10px' !important;
  } */
`;

export const Title = styled.h1`
  font-size: 21px;
  margin-bottom: 16px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 0 60px;
`;

export const Content = styled.div`
  width: 100%;
  height: 630px;
  padding: 0 60px;
`;

export const NavContainer = styled.div`
  width: 100%;
`;

export const NavButton = styled.button`
  border: ${(props) => (props.active ? '2px solid #a0bd39' : 'none')};
  border-top-left-radius: ${(props) => (props.active ? '6px' : '0')};
  border-top-right-radius: ${(props) => (props.active ? '6px' : '0')};
  border-bottom: none;
  background-color: ${(props) => (props.active ? '#a0bd39' : '#FFF')};
  cursor: pointer;
  font-weight: bold;
  padding: 5px 10px;
  color: ${(props) => (props.active ? '#FFF' : '#4d4d4d')};
  //margin-bottom: -2px;

  svg {
    color: #a0bd39;
    width: 30px;
    height: 30px;
  }
`;

export const TableTitle = styled.h2`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  margin-bottom: 5px;
`;

export const AddBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
`;

export const AddButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    color: #a0bd39;
    width: 30px;
    height: 30px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;

  .actionBtn {
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #79ac34;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const DeletePointSelectedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  background: transparent;
  border: 0;
  /* text-align: left; */
  color: #79ac34;
  padding: 0;
  width: 100%;
  cursor: default !important;

  span {
    font-size: 12px;
  }

  .selectAll {
    margin-left: 4px;
    padding-top: 2px;
  }
`;
