/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { MdAddCircle, MdModeEdit, MdDelete, MdCheck } from 'react-icons/md';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from '@mui/material';
import { toast } from 'react-toastify';
import Checkbox from 'react-custom-checkbox';
import { DashboardContext } from '../../index';
import { useMap } from '../../../../hooks/Map';
import * as S from './styled';
import { useModal } from '../../../../hooks/Modal';
import { appConfig } from '../../../../config';
import api from '../../../../services/api';
import { useAuth } from '../../../../hooks/Auth';
import Toast from '../../../../components/Toast';
import Management from './components/Management';
import Parameters from './components/Parameters';
import Pragues from './components/Pragues';
import Weeds from './components/Weeds';
import Disease from './components/Disease';
import ModalNewProblem from './components/Parameters/components/ModalNewProblem';
import ModalParametersNewRecommendation from './components/Parameters/components/ModalParametersNewRecommendation';
import ModalEditProblem from './components/Parameters/components/ModalEditProblem';
import ModalParametersEditRecommendation from './components/Parameters/components/ModalParametersEditRecommendation';

const DataManagement = () => {
  const {
    openedModal,
    itemSelectedVisible,
    setUploadedFiles
  } = useContext(DashboardContext);
  const { closeModal, openModal } = useModal();
  const { token, user, getNotifications } = useAuth();
  const { selectedFarmId } = useMap();

  const [selectedMenu, setSelectedMenu] = useState('adm');

  return (
    <S.Container>
      <S.Title>Administração de Dados</S.Title>
      <S.ContentContainer>
        <S.NavContainer>
          <S.NavButton
            active={selectedMenu === 'adm'}
            onClick={() => setSelectedMenu('adm')}
          >Administração
          </S.NavButton>
          <S.NavButton
            active={selectedMenu === 'parameters'}
            onClick={() => setSelectedMenu('parameters')}
          >Parâmetros
          </S.NavButton>
          <S.NavButton
            active={selectedMenu === 'pragues'}
            onClick={() => setSelectedMenu('pragues')}
          >Pragas
          </S.NavButton>
          <S.NavButton
            active={selectedMenu === 'disease'}
            onClick={() => setSelectedMenu('disease')}
          >Doenças
          </S.NavButton>
          <S.NavButton
            active={selectedMenu === 'weeds'}
            onClick={() => setSelectedMenu('weeds')}
          >Ervas Daninhas
          </S.NavButton>
        </S.NavContainer>
        <hr className="nav__hr" />
      </S.ContentContainer>
      <S.Content>
        {selectedMenu === 'adm' && <Management />}
        {selectedMenu === 'parameters' && <Parameters />}
        {selectedMenu === 'pragues' && <Pragues />}
        {selectedMenu === 'weeds' && <Weeds />}
        {selectedMenu === 'disease' && <Disease />}
      </S.Content>
    </S.Container>
  );
};

export default DataManagement;
