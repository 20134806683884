/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../index';
import * as S from './styled';
import { useModal } from '../../../../hooks/Modal';
import { useMap } from '../../../../hooks/Map';
import { useAuth } from '../../../../hooks/Auth';
import api from '../../../../services/api';
import { appConfig } from '../../../../config';
import Toast from '../../../../components/Toast';
import Register from './components/Register';
import Alert from './components/Alert';
import Monitoring from './components/Monitoring';
import Recommendations from './components/Recommendations';
import Technical from './components/Technical';
import ModalNewAlert from './components/Alert/components/ModalNewAlert';
import ModalNewRoutine from './components/Monitoring/components/ViewRoutine/ModalNewRoutine';
import ModaExportRoutine from './components/Monitoring/components/ViewRoutine/ModaExportRoutine';
import ModalNewRecommendation from './components/Recommendations/components/ModalNewRecommendation';
import ModalNewTechnical from './components/Technical/components/ModalNewTechnical';
import ModalEditRecommendation from './components/Recommendations/components/ModalEditRecommendation';
import ViewTechnical from './components/Technical/components/ViewTechnical';
import ViewRoutine from './components/Monitoring/components/ViewRoutine';
import ViewRecommendation from './components/Recommendations/components/ViewRecommendation';
import ViewRecommendationMade from './components/Recommendations/components/ViewRecommendationMade';
import ModalRecommendationRevaluation from './components/Recommendations/components/ModalRecommendationRevaluation';
import ModalNewTask from './components/Technical/components/ModalNewTask';
import ModalDeleteRoutine from './components/Monitoring/components/ViewRoutine/ModalDeleteRoutine';

const PestMonitoring = () => {
  const { token } = useAuth();
  const { selectedFarmId } = useMap();
  const {
    openedModal,
    itemSelectedVisible,
    setItemSelectedVisible,
    setListTypeManager } = useContext(DashboardContext);
  const { closeModal, openModal } = useModal();

  const [selectedMenu, setSelectedMenu] = useState('monitoring');

  const getTypeList = async () => {
    try {
      const response = await api.get(
        `${appConfig.apiHosts.manager}/Type/List`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setListTypeManager(response.data.data.types);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setItemSelectedVisible('');
  }, [selectedMenu]);

  useEffect(() => {
    getTypeList();

    if (!selectedFarmId) {
      return toast(
        <Toast
          title="Por favor selecione uma fazenda"
        />,
        { type: 'warning' }
      );
    }
  }, []);

  return (
    <S.Container>
      <S.Title>Monitoramento de Campo</S.Title>
      <S.ContentContainer>
        <S.NavContainer>
          {/* <S.NavButton
            active={selectedMenu === 'register'}
            onClick={() => setSelectedMenu('register')}
          >Registro
          </S.NavButton>
          <S.NavButton
            active={selectedMenu === 'alert'}
            onClick={() => setSelectedMenu('alert')}
          >Alertas e Reportes
          </S.NavButton> */}
          <S.NavButton
            active={selectedMenu === 'monitoring'}
            onClick={() => setSelectedMenu('monitoring')}
          >Monitoramento
          </S.NavButton>
          <S.NavButton
            active={selectedMenu === 'recommendations'}
            onClick={() => setSelectedMenu('recommendations')}
          >Recomendações
          </S.NavButton>
          {/* <S.NavButton
            active={selectedMenu === 'technical'}
            onClick={() => setSelectedMenu('technical')}
          >Técnicos
          </S.NavButton> */}
        </S.NavContainer>
        <hr />
        {selectedFarmId && (
          <S.Content>
            {/* {selectedMenu === 'register' &&
              <Register />}
            {selectedMenu === 'alert' &&
              <Alert />} */}
            {selectedMenu === 'monitoring' &&
              <Monitoring />}
            {selectedMenu === 'monitoring' && itemSelectedVisible === 'viewRoutine' && (
              <ViewRoutine />
            )}
            {selectedMenu === 'recommendations' &&
              <Recommendations />}
            {selectedMenu === 'recommendations' && itemSelectedVisible === 'viewRecommendation' && (
              <ViewRecommendation />
            )}
            {selectedMenu === 'recommendations' && itemSelectedVisible === 'viewRecommendationMade' && (
              <ViewRecommendationMade />
            )}
            {/* {selectedMenu === 'technical' && itemSelectedVisible !== 'viewTechnical' && (
              <Technical />
            )}
            {selectedMenu === 'technical' && itemSelectedVisible === 'viewTechnical' && (
              <ViewTechnical />
            )} */}
          </S.Content>
        )}
      </S.ContentContainer>
      {openedModal === 'modalNewAlert' && <ModalNewAlert />}
      {openedModal === 'modalNewRoutine' && <ModalNewRoutine />}
      {openedModal === 'modalNewRecommendation' && <ModalNewRecommendation />}
      {openedModal === 'modalNewTechnical' && <ModalNewTechnical />}
      {openedModal === 'modalEditProblem' && <modalEditProblem />}
      {openedModal === 'modalRecommendationRevaluation' && <ModalRecommendationRevaluation />}
      {openedModal === 'modalEditRecommendation' && <ModalEditRecommendation />}
      {openedModal === 'modalNewTask' && <ModalNewTask />}
      {openedModal === 'ModaExportRoutine' && <ModaExportRoutine />}
      {openedModal === 'modalDeleteRoutine' && <ModalDeleteRoutine />}
    </S.Container>

  );
};

export default PestMonitoring;
