/* eslint-disable prettier/prettier */
/* eslint-disable import/extensions */
/* eslint-disable no-lone-blocks */
/* eslint-disable prettier/prettier */
/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
import $ from 'jquery';
import React, { createContext, useContext, useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import pdfMake from 'pdfmake/build/pdfmake';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import ht from 'date-fns/esm/locale/ht/index.js';
import * as turf from '@turf/turf';
import { getPointResolution } from 'ol/proj';
import Projection from 'ol/proj/Projection';
import { MdCropLandscape, MdCropPortrait } from 'react-icons/md';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import CardSelect from '../../../../components/CardSelect';
import Toast from '../../../../components/Toast';
import { useAuth } from '../../../../hooks/Auth';
import { useMap } from '../../../../hooks/Map';
import api from '../../../../services/api';
import { DashboardContext } from '../../index';
import * as S from './styled';
import RequestOwnerNameModal from './components/RequestOwnerNameModal';
import { useModal } from '../../../../hooks/Modal';
import { reportDepthParamMultiplePages } from '../../../../reports/reportDepthParamMultiplePages';
import { reportDepthParamMultiplePagesLandscape } from '../../../../reports/reportDepthParamMultiplePagesLandscape';
import { reportDepthParam } from '../../../../reports/reportDepthParam';
import { useFullLoading } from '../../../../hooks/FullLoading';
import { getScaleByValue } from '../../../../helpers/getScaleByValue';
import { getDepth, setDepth } from '../../../../helpers/parseDephPoint';
import { SwitchButton } from '../../../../components/SwitchButton';
import { reportDepthParamLandscape } from '../../../../reports/reportDepthParamLandscape';
import { appConfig } from '../../../../config';

export const ReportContext = createContext({});

const Report = () => {
  const [param, setParam] = useState('');
  const [layer, setLayer] = useState('');
  const allParamsRel = [];
  const [resultsVisible, setResultsVisible] = useState(true);
  const [listMapsVisible, setListMapsVisible] = useState(false);
  const [responseParams, setResponseParams] = useState();
  const ParamOptions = ['Argila', 'MO', 'MO x Arg', 'PH', 'Ca', 'Mg', 'K', 'AI', 'CTC', 'V%', 'P', 'Sal. AI', 'S'];
  const DepthOptions = ['0-10', '0-20', '0-20-40', '0-10-20', '0-10-20-40', '0-10-20-30-40', '0-10-20-30-40-60'];
  const [disableButton, setDisableButton] = useState(true);
  const mapId = nextId();
  const [table, setTable] = useState('');
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  const {
    dashboardState,
    setDashboardState,
    setSubdivisions,
    resultVisible,
    setResultVisible,
    setFields,
    fields,
    handleActionSideNavigation
  } = useContext(DashboardContext);
  const { token, user } = useAuth();
  const {
    selectedFarmId,
    setListSubdivisions,
    renderSubdivisions,
    setLegendResultsValue,
    renderSubdivisionsWithColors,
    listSubdivisions,
    removeMappingGeometries,
    removePointGeometries,
    // reports,
    setListFields,
    renderFieldsNoLabel,
    renderFields,
    refMap,
    removeGeometriesColoredSubdivisions,
    removeRecommendationZone,
    removeColoredRecommendationZone,
    reportEditId,
    setReportEditId,
    selectedFarmGeoJson,
    setFarmStyle,
    renderFarm,
    removeSubdivisionGeometries,
    removeFieldGeometries,
    croquis,
    setCroquis,
    renderCollectionPoints,
    renderSubdivisionsNoLabel,
    // setReports,
    mapScale,
    getHorizontalLengthOfGeometry,
    resolution,
    setResolution,
    scale,
    setScale,
    updateMapResolution,
    updateMapScale,
    scaleLine,
    zoomInGeometry,
    orientation,
    setOrientation
  } = useMap();
  const [disableUnicReport, setDisableUnicReport] = useState(true);
  useEffect(() => {
    $('#scale').on('keypress', (event) => {
      if (event.charCode < 46 || event.charCode > 57) return false;
    });
  }, []);
  useEffect(() => {
    if (reportEditId && reports) {
      setLayer(reports?.filter((value) => value.id === reportEditId)[0].layer);
      setParam(reports?.filter((value) => value.id === reportEditId)[0].param);
    }
    selectedFarmGeoJson && zoomInGeometry(selectedFarmGeoJson);
    // setScale(Number(document.getElementsByClassName('ol-scale-text')[0].innerHTML.split(':')[1]));
    setScale(document.getElementsByClassName('ol-scale-text')[0].innerHTML.split(':')[1].replaceAll('&nbsp;', ''));
    removeRecommendationZone();
  }, []);

  const getSubdivisions = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data, '#9FBC38');
      setListSubdivisions(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([['farmId', selectedFarmId],
        ['userId', user.user_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data, '#000');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  let subdivisonColors = [];
  let subdivisons = [];

  const handleViewParam = async (parameter, depth) => {
    try {
      if (depth === '20-40' || depth === '0-20-40' || depth === '0-10-20-40') {
        const params02040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-20-40']
        ]);
        const resp02040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params02040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const params0102040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-10-20-40']
        ]);
        const resp0102040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params0102040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const subcolor = [];

        resp02040.data.data.forEach((v) => {
          subcolor.push(v);
        });

        resp0102040.data.data.forEach((v) => {
          subcolor.push(v);
        });

        subdivisonColors.push({ param: parameter, depth, color: subcolor });
      } else {
        const params = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', depth]]);
        const response = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        subdivisonColors.push({ param: parameter, depth, color: response.data.data });
        setDisableUnicReport(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getColorOnePage = async (parameter, depth) => {
    try {
      if (depth === '20-40' || depth === '0-20-40' || depth === '0-10-20-40') {
        const params02040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-20-40']
        ]);
        const resp02040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params02040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const params0102040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-10-20-40']
        ]);
        const resp0102040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params0102040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const subdivisonsColors = [];
        subdivisonsColors.push(resp02040.data.data);

        resp0102040.data.data.forEach((color) => {
          subdivisonsColors[0].push(color);
        });

        renderSubdivisionsWithColors(listSubdivisions, subdivisonsColors[0], '#000');
      } else {
        const params = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', setDepth(depth)]]);
        const response = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const paramsSubdivision = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['userId', user.user_id]
        ]);
        const responseSub = await api.get(
          `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
          {
            params: paramsSubdivision,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        renderSubdivisionsWithColors(responseSub.data.data, response.data.data, '#000');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { openModal } = useModal();

  const getPoints = async () => {
    const params = new URLSearchParams([
      ['farmId', selectedFarmId],
      ['userId', user.user_id]
    ]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.field}/DataCollectionPoint/GetActiveDataCollectionPointListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const cPoints = await response.data.data.filter(
        (value) => value.features[0].properties.type === 'REFERENCIA_COLETA'
      );
      renderCollectionPoints(cPoints, '#9FBC38');
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateLegend = async (parameter) => {
    try {
      const params = new URLSearchParams([['ParamId', parameter]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/ReportLegend/GetReportLegendByParam`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const responseParams = response.data.data;
      setLegendResultsValue(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditRenderedMap = () => {
    const report = reports?.filter((value) => value.id === reportEditId)[0];
    report.layer = layer;
    report.param = param;
  };

  useEffect(() => {
    // getSubdivisions();
    getFields();
    getColorOnePage(param, layer);
    setDisableButton(false);
  },
  [param, layer]);
  const { activeLoading, desactiveLoading, setloadingMessage } = useFullLoading();
  useEffect(() => {
    if (loading) {
      return activeLoading();
    }
    setloadingMessage();
    desactiveLoading();
  }, [loading]);

  useEffect(() => {
    if (selectedFarmId) {
      activeLoading();
      setloadingMessage('Ajustando Mapa');
      if (croquis.length === 0 && selectedFarmGeoJson) {
        setTimeout(() => {
          renderFarm(selectedFarmGeoJson, '#000');
          getSubdivisions();
          getFields();
        }, 2000);
      }
      setTimeout(() => {
        setScale(mapScale().replaceAll('&nbsp;', '.'));
        desactiveLoading();
        setloadingMessage();
      }, 30000);
    }
    refMap.updateSize();
  }, [orientation]);

  useEffect(() => {
    if (!selectedFarmId) {
      return toast(
        <Toast
          title="Por favor selecione uma fazenda"
          content="Para que as subdivisões sejam carregadas é nescessário selecionar uma fazenda"
        />,
        { type: 'warning' }
      );
    }
    // getSubdivisions();
    setResultVisible(true);
    setLegendResultsValue([]);
    removeMappingGeometries();
    removePointGeometries();
    removeColoredRecommendationZone();
  }, []);

  const handleClick = async () => {
    setTimeout(() => {
      const canvas = document.getElementsByTagName('canvas')[0];
      const imgData = canvas.toDataURL('image/png');
      const report = orientation === 'Retrato' ? reportDepthParam(
        { reportTitle: `RESULTADO DE ANÁLISE DE SOLO - ${new Date().getFullYear()}`,
          farmName: selectedFarmGeoJson.features[0].properties.farm_name,
          depth: layer,
          param,
          mapImage: imgData,
          scale: mapScale().replaceAll('&nbsp;', '')
        }) : reportDepthParamLandscape(
        { reportTitle: `RESULTADO DE ANÁLISE DE SOLO - ${new Date().getFullYear()}`,
          farmName: selectedFarmGeoJson.features[0].properties.farm_name,
          depth: layer,
          param,
          mapImage: imgData,
          scale: mapScale().replaceAll('&nbsp;', '')
        });
      pdfMake.createPdf(report).open();
    }, 1000);
  };

  let ordenedReports = {
    argila: [],
    mo: [],
    ph: [],
    ca: [],
    mg: [],
    k: [],
    ai: [],
    ctc: [],
    v: [],
    sal: [],
    moarg: [],
    p: [],
    s: []
  };

  const exportReportPages = (ownerName, reportPages) => {
    setLoading(true);
    setloadingMessage('Gerando PDF');
    reports.forEach((value) => {
      if (value.param === 'Argila') {
        return ordenedReports.argila.push(value);
      }
      if (value.param === 'MO') {
        return ordenedReports.mo.push(value);
      }
      if (value.param === 'PH') {
        return ordenedReports.ph.push(value);
      }
      if (value.param === 'Ca') {
        return ordenedReports.ca.push(value);
      }
      if (value.param === 'Mg') {
        return ordenedReports.mg.push(value);
      }
      if (value.param === 'K') {
        return ordenedReports.k.push(value);
      }
      if (value.param === 'AI') {
        return ordenedReports.ai.push(value);
      }
      if (value.param === 'CTC') {
        return ordenedReports.ctc.push(value);
      }
      if (value.param === 'V%') {
        return ordenedReports.v.push(value);
      }
      if (value.param === 'Sal. AI') {
        return ordenedReports.sal.push(value);
      }
      if (value.param === 'MO x Arg') {
        return ordenedReports.moarg.push(value);
      }
      if (value.param === 'P') {
        return ordenedReports.p.push(value);
      }
      if (value.param === 'S') {
        return ordenedReports.s.push(value);
      }
    });
    const parsedOrdenedReport = [
      ordenedReports.argila.sort((a, b) => a.depth < b.depth ? -1 : true)[0],
      ...ordenedReports.moarg.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.mo.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.ph.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.ca.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.mg.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.k.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.ai.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.ctc.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.v.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.sal.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.p.sort((a, b) => a.depth < b.depth ? -1 : true),
      ...ordenedReports.s.sort((a, b) => a.depth < b.depth ? -1 : true)

    ];
    const report = orientation === 'Retrato' ? reportDepthParamMultiplePages(
      parsedOrdenedReport,
      ownerName,
      table,
      reportPages,
      croquis,
      selectedFarmGeoJson,
      fields,
      listSubdivisions,
      mapScale().replaceAll('&nbsp;', '')) : reportDepthParamMultiplePagesLandscape(
      parsedOrdenedReport,
      ownerName,
      table,
      reportPages,
      croquis,
      selectedFarmGeoJson,
      fields,
      listSubdivisions,
      mapScale().replaceAll('&nbsp;', ''));
    setTimeout(() => {
      setLoading(false);
    }, 20000);
    pdfMake.createPdf(report).download(`Mapa de Espacializacao ${selectedFarmGeoJson?.features[0].properties?.farm_name}_${new Date().getFullYear()}`);
    ordenedReports = {
      argila: [],
      mo: [],
      ph: [],
      ca: [],
      mg: [],
      k: [],
      ai: [],
      ctc: [],
      v: [],
      sal: [],
      moarg: [],
      p: [],
      s: []
    };
    subdivisonColors = [];
    setReports([]);
  };

  const captureImage = async (paramsRel) => {
    try {
      const paramsSubdivision = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const responseSub = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params: paramsSubdivision,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      subdivisons = responseSub.data.data;
    } catch (error) {
      console.error(error);
    }
    paramsRel.forEach(async (pRel) => {
      await handleViewParam(pRel.param, pRel.depth);
    });
    setTimeout(() => {
      subdivisonColors.forEach((value, index) => {
        setTimeout(() => {
          renderSubdivisionsWithColors(subdivisons, value.color, '#AED329');
          setTimeout(() => {
            const canvas = document.getElementsByTagName('canvas')[0];
            reports.push({
              id: mapId,
              map: `Mapa ${reports.length + 1}`,
              param: value.param,
              layer: value.depth,
              reportTitle: `RESULTADO DE ANÁLISE DE SOLO - ${new Date().getFullYear()}`,
              farmName: selectedFarmGeoJson.features[0].properties.farm_name,
              depth: value.depth,
              mapImage: canvas?.toDataURL('image/png')
            });
          }, 100);
        }, (index + 1) * 1800);
      });
      setTimeout(() => {
        setLoading(false);
        openModal();
      }, paramsRel.length * 2500);
    }, 60000);
  };

  const getTable = async () => {
    removeSubdivisionGeometries();
    removeFieldGeometries();
    setLoading(true);
    removeGeometriesColoredSubdivisions();
    try {
      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetReportListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setTable(response.data.data);
      setTimeout(() => {
        setloadingMessage('Colhendo Resultados');
      }, 9000);

      response.data.data.map((value) => {
        if (value.prof === '0-20-40' || value.prof === '0-10-20-40') {
          value.prof = '20-40';
        }
        if (
          Number(value.argila) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'Argila')
            .length < 1
        ) {
          allParamsRel.push({ param: 'Argila', depth: value.prof });
        }
        if (
          Number(value.pH_CaCl2) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'PH')
            .length < 1
        ) {
          allParamsRel.push({ param: 'PH', depth: value.prof });
        }
        if (
          Number(value.mo) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'MO')
            .length < 1
        ) {
          allParamsRel.push({ param: 'MO', depth: value.prof });
        }
        if (
          Number(value.ca) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'Ca')
            .length < 1
        ) {
          allParamsRel.push({ param: 'Ca', depth: value.prof });
        }
        if (
          Number(value.mg) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'Mg')
            .length < 1
        ) {
          allParamsRel.push({ param: 'Mg', depth: value.prof });
        }
        if (
          Number(value.k) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'K')
            .length < 1
        ) {
          allParamsRel.push({ param: 'K', depth: value.prof });
        }
        if (
          Number(value.al) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'AI')
            .length < 1
        ) {
          allParamsRel.push({ param: 'AI', depth: value.prof });
        }
        if (
          Number(value.ctc) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'CTC')
            .length < 1
        ) {
          allParamsRel.push({ param: 'CTC', depth: value.prof });
        }
        if (
          Number(value.v) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'V%')
            .length < 1
        ) {
          allParamsRel.push({ param: 'V%', depth: value.prof });
        }
        if (
          Number(value.p) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'P')
            .length < 1
        ) {
          allParamsRel.push({ param: 'P', depth: value.prof });
        }
        if (
          Number(value.satAl) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'Sal. AI')
            .length < 1
        ) {
          allParamsRel.push({ param: 'Sal. AI', depth: value.prof });
        }
        if (
          Number(value.argila) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'MO x Arg')
            .length < 1
        ) {
          allParamsRel.push({ param: 'MO x Arg', depth: value.prof });
        }
        if (
          Number(value.s) >= 0 &&
          allParamsRel.filter((ar) => ar.depth === value.prof && ar.param === 'S')
            .length < 1
        ) {
          allParamsRel.push({ param: 'S', depth: value.prof });
        }
        return null;
      });
      setloadingMessage('Desenhando Croqui');
      if (croquis.length === 0) {
        const croq = [];
        const canvas = document.getElementsByTagName('canvas')[0];
        croq.push(canvas.toDataURL('image/png'));
        setTimeout(() => {
          getFields();
        }, 2000);
        setTimeout(() => {
          croq.push(canvas.toDataURL('image/png'));
          removeFieldGeometries();
          renderFieldsNoLabel(fields, '#000');
          getSubdivisions();
        }, 5000);
        setTimeout(() => {
          croq.push(canvas.toDataURL('image/png'));
          removeSubdivisionGeometries();
          renderSubdivisionsNoLabel(listSubdivisions, '#AED329');
          getPoints();
        }, 60000);
        setTimeout(() => {
          croq.push(canvas.toDataURL('image/png'));
          setCroquis(croq);
          getSubdivisions();
        }, 84000);
      }
      setTimeout(() => {
        removePointGeometries();
        setloadingMessage('Desenhando Resultados e Coletando Imagens');
        captureImage(allParamsRel);
        // openModal();
      }, 100000);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGenerateLegend(param);
  }, [param]);

  return (
    <ReportContext.Provider value={{ exportReportPages, orientation }}>
      <S.Container>
        <h1>Relatório</h1>
        <hr />
        <p>
          Utilize as opções abaixo para adicionar as camadas desejadas no mapa
          ao lado
        </p>
        <S.Content>
          {!selectedFarmId ? (
            <h3>Por favor selecione uma fazenda</h3>
          ) : (
            <>
              {!listMapsVisible && (
              <>
                <span>Escala:</span>
                <Input
                  className="selectInput"
                  id="scale"
                  name="scale"
                  type="text"
                  value={scale}
                  onChange={(e) => {
                    setScale(e.target.value);
                    updateMapScale(e.target.value);
                  }}
                />
                <S.TitleBox>Escolha o parâmetro</S.TitleBox>
                <S.DivTags>
                  {ParamOptions.map((value) => {
                    return (
                      <CardSelect
                        text={value}
                        onClick={() => {
                          setParam(value);
                        }}
                        selected={param === value}
                      />
                    );
                  })}
                </S.DivTags>
                <S.TitleBox>Escolha a camada</S.TitleBox>
                <S.DepthContainer>
                  <CardSelect
                    text={getDepth(DepthOptions[0])}
                    onClick={() => {
                      setLayer(getDepth(DepthOptions[0]));
                      handleViewParam(param, getDepth(DepthOptions[0]));
                    }}
                    selected={layer === getDepth(DepthOptions[0])}
                  />
                  <CardSelect
                    text={getDepth(DepthOptions[3])}
                    onClick={() => {
                      setLayer(getDepth(DepthOptions[3]));
                      handleViewParam(param, getDepth(DepthOptions[3]));
                    }}
                    selected={layer === getDepth(DepthOptions[3])}
                  />

                  <CardSelect
                    text={getDepth(DepthOptions[2])}
                    onClick={() => {
                      setLayer(getDepth(DepthOptions[2]));
                      handleViewParam(param, getDepth(DepthOptions[2]));
                    }}
                    selected={layer === getDepth(DepthOptions[2])}
                  />
                  <br />
                </S.DepthContainer>
                <S.DepthContainer>
                  <CardSelect
                    text={getDepth(DepthOptions[1])}
                    onClick={() => {
                      setLayer(getDepth(DepthOptions[1]));
                      handleViewParam(param, getDepth(DepthOptions[1]));
                    }}
                    selected={layer === getDepth(DepthOptions[1])}
                  />
                  <CardSelect
                    text={getDepth(DepthOptions[5])}
                    onClick={() => {
                      setLayer(getDepth(DepthOptions[5]));
                      handleViewParam(param, getDepth(DepthOptions[5]));
                    }}
                    selected={layer === getDepth(DepthOptions[5])}
                  />
                  <CardSelect
                    text={getDepth(DepthOptions[6])}
                    onClick={() => {
                      setLayer(getDepth(DepthOptions[6]));
                      handleViewParam(param, getDepth(DepthOptions[6]));
                    }}
                    selected={layer === getDepth(DepthOptions[6])}
                  />
                </S.DepthContainer>
              </>
              )}
            </>
          )}
          <S.TextSelectorOrientation>Orientação do Relatório</S.TextSelectorOrientation>
          <SwitchButton
            iconFirstOption={<MdCropPortrait />}
            textFirstOption="Retrato"
            onClickFirstOptionAction={() => setOrientation('Retrato')}
            iconSecondOption={<MdCropLandscape />}
            textSecondOption="Paisagem"
            onClickSecondOptionAction={() => setOrientation('Paisagem')}
          />
        </S.Content>
        {!reportEditId && selectedFarmId && (
        <Button
          text="Imprimir único parametro"
          onClick={() => {
            handleClick();
          }}
          className="buttonAddMap"
          disabled={disableUnicReport}
        />
        )}
        <Button
          text="Imprimir relatório completo"
          onClick={() => getTable()}
        />
        <RequestOwnerNameModal />

      </S.Container>
    </ReportContext.Provider>
  );
};
export default Report;
