/* eslint-disable prettier/prettier */
import { kml } from '@tmcw/togeojson';
import * as turf from '@turf/turf';
import JSZip from 'jszip';
import proj4 from 'proj4';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  MdCloudUpload,
  MdDelete,
  MdModeEdit,
  MdRemoveCircle
} from 'react-icons/md';
import { toast } from 'react-toastify';
import * as shp from 'shpjs';
import Toast from '../../../../components/Toast';
import { appConfig } from '../../../../config';
import { parseCoordinatesMultiWalkMultiFeaturesTo3857 } from '../../../../helpers/parseCoordinates';
import { useAuth } from '../../../../hooks/Auth';
import { useMap } from '../../../../hooks/Map';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { DashboardContext } from '../../index';
import ConfirmDialog from './components/ConfirmDialog';
import * as S from './styled';

export const InformationContext = createContext({});

const Information = () => {
  const {
    renderMapping,
    viewMapping,
    modifyMappingField,
    selectedFarmId,
    removeMappingGeometries,
    readLayers,
    createMapping,
    removeRecommendationZone,
    removeColoredRecommendationZone,
    selectedFarmGeoJson,
    updateSizeMap
  } = useMap();
  const { setDashboardState, farms, setResultVisible, setShowResult } =
    useContext(DashboardContext);
  const { user, token } = useAuth();
  const { openModal } = useModal();
  const [fieldsMapping, setFieldsMapping] = useState([]);
  const [mapping, setMapping] = useState();
  const [farmNotSelected, setFarmNotSelected] = useState(true);

  const getAllFieldsMapping = async () => {
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que os caminhamentos sejam carregados é necessário selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }

      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/FieldMapping/GetActiveFieldMappingListByFarmIdWhereSubdivisionIdIsNull`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFieldsMapping(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const RegisterFieldMapping = async (geojson, farm_id) => {
    try {
      const properties = {
        farm_id,
        description: 'Caminhamento 1',
        created_by: user.user_id
      };
      geojson.features[0].properties = properties;
      await api.post(
        `${appConfig.apiHosts.field}/FieldMapping/PostFieldMapping`,
        geojson,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      getAllFieldsMapping();
      removeMappingGeometries();
      viewMapping(geojson);
      toast(<Toast title="Caminhamento cadastrado com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(<Toast title="Não foi possível cadastrar o caminhamento" />, {
        type: 'error'
      });
    }
  };

  const registerShapefile = (geojson) => {
    if (
      geojson.features[0].geometry.type !== 'LineString' &&
      geojson.features[0].geometry.type !== 'MultiLineString'
    ) {
      return toast(
        <Toast
          title="O shapefile contem uma geometria diferente do tipo aceito"
          content={`Tipo Aceito: 'LineString' - Tipo Enviado: '${geojson.features[0].geometry.type}'`}
        />,
        {
          type: 'error'
        }
      );
    }
    if (
      geojson.features.length === 1 &&
      geojson.features[0].geometry.type === 'MultiLineString'
    ) {
      const parsedGeoJson =
        parseCoordinatesMultiWalkMultiFeaturesTo3857(geojson);
      if (
        turf.booleanIntersects(
          selectedFarmGeoJson.features[0].geometry,
          parsedGeoJson.features[0].geometry
        ) &&
        selectedFarmId === selectedFarmGeoJson.features[0].properties.farm_id
      ) {
        RegisterFieldMapping(
          parsedGeoJson,
          selectedFarmGeoJson.features[0].properties.farm_id
        );
        return renderMapping(parsedGeoJson);
      }
    }
    if (geojson.features.length > 1) {
      const parsedGeoJson =
        parseCoordinatesMultiWalkMultiFeaturesTo3857(geojson);
      const walkGeoJsons = parsedGeoJson.features.map((feature) => ({
        type: 'FeatureCollection',
        features: [feature]
      }));
      walkGeoJsons.forEach((json) => {
        if (
          turf.booleanIntersects(
            selectedFarmGeoJson.features[0].geometry,
            json.features[0].geometry
          ) &&
          selectedFarmId === selectedFarmGeoJson.features[0].properties.farm_id
        ) {
          RegisterFieldMapping(
            json,
            selectedFarmGeoJson.features[0].properties.farm_id
          );
          return renderMapping(json);
        }
      });
    } else {
      const parsedCoordinates = geojson.features[0].geometry.coordinates.map(
        (value) => proj4('EPSG:4326', 'EPSG:3857', [value[0], value[1]])
      );
      geojson.features[0].geometry.coordinates = parsedCoordinates;
      farms.forEach((farm) => {
        if (
          turf.booleanIntersects(
            farm.features[0].geometry,
            geojson.features[0].geometry
          ) &&
          selectedFarmId === farm.features[0].properties.farm_id
        ) {
          RegisterFieldMapping(geojson, farm.features[0].properties.farm_id);
          return renderMapping(geojson);
        }
      });
      const sFarm = farms.filter(
        (value) => value.features[0].properties.farm_id === selectedFarmId
      )[0];
      if (
        !turf.booleanIntersects(
          sFarm.features[0].geometry,
          geojson.features[0].geometry
        )
      ) {
        return toast(
          <Toast title="Caminhamento fora dos limites da fazenda" />,
          {
            type: 'error'
          }
        );
      }
    }
  };

  const convertToLayerKmz = async (buffer) => {
    const blob = new Blob([buffer], { type: 'application/zip' });
    const zip = new JSZip();
    await zip.loadAsync(blob).then(async (zipped) => {
      const file = await zipped.file('doc.kml').async('blob');
      const url = URL.createObjectURL(file);
      fetch(url)
        .then((response) => response.text())
        .then((xml) => {
          const kmlReadResult = kml(
            new DOMParser().parseFromString(xml, 'text/xml')
          );
          registerShapefile(kmlReadResult);
        });
    });
  };

  const convertToLayerKml = (buffer) => {
    const blob = new Blob([buffer], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    fetch(url)
      .then((response) => response.text())
      .then((xml) => {
        const kmlReadResult = kml(
          new DOMParser().parseFromString(xml, 'text/xml')
        );
        registerShapefile(kmlReadResult);
      });
  };

  const convertToLayerZip = (buffer) => {
    shp(buffer).then((geojson) => registerShapefile(geojson));
  };

  const handleUploadFile = (file, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState !== 2 || reader.error) {
        return null;
      }
      if (
        type === 'application/zip' ||
        type === 'application/x-zip-compressed' ||
        type === 'zip'
      ) {
        return convertToLayerZip(reader.result);
      }
      if (type === 'application/vnd.google-earth.kml+xml') {
        return convertToLayerKml(reader.result);
      }
      if (type === 'application/vnd.google-earth.kmz') {
        return convertToLayerKmz(reader.result);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDeleteMapping = async (
    id = mapping.features[0].properties.field_mapping_id
  ) => {
    const params = new URLSearchParams([
      ['fieldMappingId', id],
      ['status', false]
    ]);
    try {
      await api.delete(
        `${appConfig.apiHosts.field}/FieldMapping/DeleteFieldMapping`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      getAllFieldsMapping();
      toast(<Toast title="Caminhamento removido com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível remover caminhamento."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const handleEditMapping = (eMapping) => {
    removeMappingGeometries();
    modifyMappingField(eMapping);
    setDashboardState('dashboard@edit_mapping');
  };

  useEffect(() => {
    getAllFieldsMapping();
    setResultVisible(false);
    setShowResult(false);
    removeRecommendationZone();
    updateSizeMap();
    if (!selectedFarmId) {
      return setFarmNotSelected(true);
    }
    return setFarmNotSelected(false);
  }, []);

  useEffect(() => {
    removeColoredRecommendationZone();
  }, []);

  return (
    <InformationContext.Provider value={{ handleDeleteMapping }}>
      <S.Container>
        <h1>Levantamento de Informações</h1>
        <hr />
        <S.Content>
          <S.CleanMappings
            type="button"
            onClick={() => removeMappingGeometries()}
          >
            <MdRemoveCircle color="#9fbc38" size={20} /> Remover Caminhamentos
          </S.CleanMappings>
          <p style={{ textAlign: 'justify' }}>
            O levantamento de informações em tempo real está disponível apenas
            na plataforma mobile. Caso já tenha realizado o levantamento,
            realizar o upload do arquivo (.zip) para iniciar o processo de
            análise.
          </p>
          {selectedFarmId ? (
            <>
              <S.ActionCard>
                <label htmlFor="upload-file-walk" className="label-upload">
                  <MdCloudUpload size={48} color="#9FBC38" />
                  <div>
                    <span>Importar arquivo para análise</span>
                    <br />
                    <p>Formatos aceitos: .zip, .kml, .kmz</p>
                  </div>
                  <input
                    type="file"
                    accept=".zip, .kml, .kmz"
                    id="upload-file-walk"
                    multiple={false}
                    onChange={(e) => {
                      const qtdArray = e.target.files.length;
                      const ext = e.target.files[qtdArray - 1].name.split('.');

                      if (
                        e.target.files[qtdArray - 1].type !==
                        'application/zip' &&
                        e.target.files[qtdArray - 1].type !==
                        'application/vnd.google-earth.kml+xml' &&
                        e.target.files[qtdArray - 1].type !==
                        'application/vnd.google-earth.kmz' &&
                        e.target.files[qtdArray - 1].type !==
                        'application/x-zip-compressed' &&
                        ext[ext.length - 1] !== 'kmz' &&
                        ext[ext.length - 1] !== 'kml' &&
                        ext[ext.length - 1] !== 'zip'
                      ) {
                        return toast(
                          <Toast
                            title="Shapefile no formato incorreto"
                            content="Formatos aceitos: .zip, .kml, .kmz "
                          />,
                          {
                            type: 'error'
                          }
                        );
                      }
                      handleUploadFile(
                        e.target.files[qtdArray - 1],
                        e.target.files[qtdArray - 1].type === ''
                          ? ext[ext.length - 1]
                          : e.target.files[qtdArray - 1].type
                      );
                    }}
                  />
                </label>
              </S.ActionCard>
              <br />
            </>
          ) : (
            <>
              <S.ActionCard className="disabled">
                <label htmlFor="upload-file" className="label-upload">
                  <MdCloudUpload size={48} color="#9FBC38" />
                  <span>Importar arquivo para análise</span>
                  <p>Formatos aceitos: .zip, .kml, .kmz</p>
                  <input
                    type="file"
                    accept=".zip, .kml, .kmz"
                    id="upload-file"
                    multiple={false}
                    disabled
                  />
                </label>
              </S.ActionCard>
              <span style={{ fontSize: '0.6rem', color: 'red', textAlign: 'center', width: '100%' }}>
                Selecione uma fazenda antes de importar um arquivo
              </span>
              <br />
            </>
          )}
          <S.AddMappingButton
            type="button"
            disabled={farmNotSelected}
            onClick={() => {
              const farm = farms.filter(
                (fFarm) =>
                  fFarm.features[0].properties.farm_id === selectedFarmId ??
                  fFarm
              )[0];
              createMapping(farm);
              setDashboardState('dashboard@register_walk');
            }}
          >
            Criar Caminhamento
          </S.AddMappingButton>
          <h1 className="subTitle">Mapeamentos Realizados</h1>
          <S.MappingsContainer>
            {fieldsMapping.map(
              (v) =>
                v.features[0].properties.farm_id === selectedFarmId && (
                  <S.MappingsCardContainer
                    key={v.features[0].properties.field_mapping_id}
                  >
                    <S.MappingsCardHeader>
                      <h4>{v.features[0].properties.description}</h4>
                    </S.MappingsCardHeader>
                    <S.MappingsCardTextContainer>
                      <span>{v.features[0].properties.farm_name}</span>
                      <p>
                        {v.features[0].properties.count_fields} Talhões -{' '}
                        {v.features[0].properties.geometry_ha} ha
                      </p>
                      <S.ButtonWrapper>
                        <button
                          type="button"
                          onClick={() => {
                            handleEditMapping(v);
                          }}
                        >
                          Editar
                          <MdModeEdit />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setMapping(v);
                            openModal();
                          }}
                        >
                          Deletar
                          <MdDelete />
                        </button>
                      </S.ButtonWrapper>
                    </S.MappingsCardTextContainer>
                    <button
                      style={{ fontWeight: 'bold' }}
                      type="button"
                      onClick={() => {
                        viewMapping(v);
                        readLayers();
                      }}
                    >
                      Adicionar ao Mapa
                    </button>
                  </S.MappingsCardContainer>
                )
            )}
          </S.MappingsContainer>
        </S.Content>
      </S.Container>
      <ConfirmDialog messageDialog="Deseja Excluir o caminhamento ?" />
    </InformationContext.Provider>
  );
};
export default Information;
