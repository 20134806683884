/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import { Container, NavItem } from './styled';

import { DashboardContext } from '../../pages/Dashboard';
import { dashboardStates } from '../../pages/Dashboard/states';
import { useAuth } from '../../hooks/Auth';

const NavBar = () => {
  const { dashboardState, setDashboardState } = useContext(DashboardContext);
  const { user } = useAuth();

  return (
    <Container>
      {dashboardStates.map((item) =>
      (item.navIcon === undefined ? null : item?.roles?.includes(user.role) ? (
        <button type="button" key={item.id} onClick={() => setDashboardState(item.path)}>
          <NavItem className={dashboardState === item.path ? 'active' : ''}>
            {item.navIcon}
          </NavItem>
        </button>
      ) : null))}
    </Container>
  );
};

export default NavBar;
