import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route as RouteReactRouterDom } from 'react-router-dom';
import { useAuth } from '../hooks/Auth';

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
  const { user } = useAuth();
  const isAuthenticated = !!user;
  return (
    <RouteReactRouterDom
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) => {
        return isPrivate === isAuthenticated ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};

Route.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  // eslint-disable-next-line react/require-default-props
  isPrivate: PropTypes.bool
};

export default Route;
