/* eslint-disable prettier/prettier */
import { kml } from '@tmcw/togeojson';
import JSZip from 'jszip';
import proj4 from 'proj4';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  MdCloudUpload,
  MdDelete,
  MdKeyboardArrowRight,
  MdModeEdit,
  MdShare
} from 'react-icons/md';
import PuffLoader from 'react-spinners/PuffLoader';
import { toast } from 'react-toastify';
import * as shp from 'shpjs';
import { blobToBase64 } from '../../../../helpers/blobToBase64';
import Toast from '../../../../components/Toast';
import { calculateArea } from '../../../../helpers/calculateArea';
import { useAuth } from '../../../../hooks/Auth';
import { useMap } from '../../../../hooks/Map';
import { useModal } from '../../../../hooks/Modal';
import api from '../../../../services/api';
import { DashboardContext } from '../../index';
import ConfirmDialog from './components/ConfirmDialog';
import ShareModal from './components/ShareModal';
import * as S from './styled';
import { appConfig } from '../../../../config';
import Button from '../../../../components/Button';

export const FarmContext = createContext({});

const Farm = () => {
  const [testeArr, setTesteArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token, user, getNotifications } = useAuth();
  const { openModal } = useModal();
  const [deleteFarm, setDeleteFarm] = useState();
  const [shareFarmId, setShareFarmId] = useState();
  const [shareList, setShareList] = useState([]);
  const [messageFarm, setMessageFarm] = useState('');
  const {
    renderFarm,
    removeGeometries,
    setSelectedFarmId,
    modifyFarm,
    setSelectedFarmHa,
    setSelectedFarmGeoJson,
    removeRecommendationZone,
    removeColoredRecommendationZone,
    calculateHa,
    disableTileMap,
    updateSizeMap,
    mapScale,
    setFarmName,
    farmName,
    setOwnerName
  } = useMap();
  const {
    setDashboardState,
    farms,
    setFarms,
    dashboardState,
    setResultVisible,
    setShowResult,
    setNotifications
  } = useContext(DashboardContext);

  const sendShareFarmNotification = async (
    email,
    message,
    notificationMessage
  ) => {
    const notification = {
      message,
      notification_message: notificationMessage,
      user_id: user.user_id,
      receiver_email: email,
      created_by: user.user_id
    };

    try {
      await api.post(
        `${appConfig.apiHosts.notification}/Notification/PostNotification`,
        notification,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      getNotifications(user.email, token).then((v) =>
        setNotifications(v.notifications)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleShareControlList = async (farmId) => {
    const params = new URLSearchParams([['FarmId', farmId]]);
    try {
      const response = await api.get(
        `${appConfig.apiHosts.farm}/FarmShareControl/GetActiveShareControlListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setShareList(response.data.data);
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível compartilhar fazenda."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const handleShareFarm = async (farmShare) => {
    try {
      const response = await api.post(
        `${appConfig.apiHosts.farm}/FarmShareControl/PostFarmShareControl`,
        farmShare,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(<Toast title="Fazenda compartilhada com sucesso" />, {
        type: 'success'
      });
      handleShareControlList(response.data.data.farm_id);
      sendShareFarmNotification(
        farmShare.email,
        `
      <img
      src="https://fmtid.fundacaomt.com.br/static/media/logo.8a7b718e.png"
      alt=""
    />
      <br/>
      <br/>
      Prezado (a),<br/>
      <p>Convidamos você a conhecer a nova Plataforma da Fundação MT, a FMTid.</p>
      <p>A FMTid irá contribuir positivamente para a interpretação dos resultados das análises de solo e consequentemente na produtividade.</p>
      <p>Nosso objetivo é facilitar de maneira segura o uso das informações de sua propriedade. Já é possível também compartilhar os seus resultados, avaliar camadas de interesse agronômico como NDVI ou imagens RGB dos seus campos.</p>
      <p>Em breve também poderá constatar sugestões dos nossos consultores, visualizar camadas de mapas de produtividade, meteorológicos e de monitoramentos de pragas e doenças. Cadastre-se ou acesse as informações da Fazenda ${farmName} que já foram compartilhadas com você, aproveite para conhecer mais a nossa plataforma.</p>
      <br/><br/><br/>
      <div style="text-align: center;">
      <a
        style="
          padding: 10px 20px;
          background: #9cb93a;
          color: #fff;
          border-radius: 10px;
          text-decoration: none;
          font-weight: bold;
        "
        href="http://www.fmtid.com.br/"
        >Acesse aqui a plataforma</a
      >
    </div>`,
        `${user.fullname} compartilhou a Fazenda ${farmName} com você.`
      );
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível compartilhar fazenda."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const handleUpdateShareFarm = async (farmShare) => {
    try {
      const response = await api.put(
        `${appConfig.apiHosts.farm}/FarmShareControl/UpdateFarmShareControl`,
        farmShare,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast(
        <Toast title="Atualização de Compartilhamento feito com sucesso" />,
        {
          type: 'success'
        }
      );

      handleShareControlList(farmShare.farm_id);
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível compartilhar fazenda."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const handleDeleteShareFarm = async (FarmShareControlId) => {
    const params = new URLSearchParams([
      ['FarmShareControlId', FarmShareControlId],
      ['Status', false]
    ]);
    try {
      const response = await api.delete(
        `${appConfig.apiHosts.farm}/FarmShareControl/DeleteFarmShareControl`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      toast(<Toast title="compartilhamento excluido com sucesso" />, {
        type: 'success'
      });

      handleShareControlList(response.data.data.farm_id);
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível remover o compartilharmento da fazenda."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const handleDeleteFarm = async (id) => {
    const params = new URLSearchParams([
      ['farmId', id],
      ['status', false]
    ]);
    try {
      await api.delete(`${appConfig.apiHosts.farm}/Farm/DeleteFarm`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const newFarms = farms.filter(
        (value) => value.features[0]?.properties.farm_id !== id
      );
      setFarms(newFarms);
      removeGeometries();

      toast(<Toast title="Fazenda removida com sucesso" />, {
        type: 'success'
      });
    } catch (error) {
      toast(
        <Toast
          title="Não foi possível remover fazenda."
          content="Tente novamente"
        />,
        { type: 'error' }
      );
    }
  };

  const registerShapefile = (geojson) => {
    if (
      geojson.features[0].geometry.type !== 'Polygon' ||
      geojson.features.length > 1
    ) {
      return toast(
        <Toast
          title="O shapefile contem uma geometria diferente do tipo aceito"
          content={`Tipo Aceito: 'Polygon' - Tipo Enviado: '${geojson.features.length > 1
              ? 'MultiPolygon'
              : geojson.features[0].geometry.type
            }'`}
        />,
        {
          type: 'error'
        }
      );
    }
    const parsedCoordinates = geojson.features[0].geometry.coordinates[0].map(
      (value) => proj4('EPSG:4326', 'EPSG:3857', [value[0], value[1]])
    );
    geojson.features[0].geometry.coordinates[0] = parsedCoordinates;
    renderFarm(geojson);
    setDashboardState('dashboard@registerFarm_shapefile');
  };

  const convertToLayerZip = (buffer) => {
    shp(buffer).then((geojson) => registerShapefile(geojson));
  };

  const convertToLayerKml = (buffer) => {
    const blob = new Blob([buffer], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    fetch(url)
      .then((response) => response.text())
      .then((xml) => {
        const kmlReadResult = kml(
          new DOMParser().parseFromString(xml, 'text/xml')
        );
        registerShapefile(kmlReadResult);
      });
  };

  const convertToLayerKmz = async (buffer) => {
    const blob = new Blob([buffer], { type: 'application/zip' });
    const zip = new JSZip();
    await zip.loadAsync(blob).then(async (zipped) => {
      const file = await zipped.file('doc.kml').async('blob');
      const url = URL.createObjectURL(file);
      fetch(url)
        .then((response) => response.text())
        .then((xml) => {
          const kmlReadResult = kml(
            new DOMParser().parseFromString(xml, 'text/xml')
          );
          registerShapefile(kmlReadResult);
        });
    });
  };

  const handleUploadFile = (file, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState !== 2 || reader.error) {
        return null;
      }
      if (
        type === 'application/zip' ||
        type === 'application/x-zip-compressed' ||
        type === 'zip'
      ) {
        return convertToLayerZip(reader.result);
      }
      if (type === 'application/vnd.google-earth.kml+xml' || type === 'kml') {
        return convertToLayerKml(reader.result);
      }
      if (type === 'application/vnd.google-earth.kmz' || type === 'kmz') {
        return convertToLayerKmz(reader.result);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const getFarms = async () => {
    setMessageFarm('');
    setLoading(true);
    try {
      const params = new URLSearchParams([['ownerId', user.user_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.farm}/Farm/GetFarmListByOwnerId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFarms(response.data.data);
      if (response.data.data.length === 0) {
        setMessageFarm('Não Existem Fazendas Cadastradas ou Compartilhadas');
      }
      await setTimeout(() => setLoading(false), 500);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setLoading(false);
      setMessageFarm('Erro ao buscar fazendas');
    }
  };

  useEffect(() => {
    getFarms();
    setResultVisible(false);
    setShowResult(false);
    removeRecommendationZone();
    removeColoredRecommendationZone();
    updateSizeMap();
  }, []);

  return (
    <FarmContext.Provider
      value={{
        handleDeleteFarm,
        deleteFarm,
        setDeleteFarm,
        shareFarmId,
        setShareFarmId,
        handleShareFarm,
        handleDeleteShareFarm,
        handleUpdateShareFarm,
        handleShareControlList,
        shareList
      }}
    >
      <S.Container>
        <S.Title>Home</S.Title>
        <hr />
        <S.SubTitle>Fazendas Cadastradas</S.SubTitle>
        {messageFarm === 'Erro ao buscar fazendas' && (
          <div>
            <S.MessageFarm color="#de1616">{messageFarm}</S.MessageFarm>
            <Button text="Tentar novamente" onClick={() => getFarms()} />
          </div>
        )}
        {messageFarm ===
          'Não Existem Fazendas Cadastradas ou Compartilhadas' && (
            <div>
              <S.MessageFarm>{messageFarm}</S.MessageFarm>
            </div>
          )}
        <S.ListFarmsContainer loading={loading}>
          {loading ? (
            <>
              <PuffLoader
                color="#79ac34"
                loading={loading}
                size={30}
                style={{ margin: 25 }}
              />
            </>
          ) : (
            farms.map((farm) => {
              return (
                <S.FarmCardContainer key={farm.features[0].properties.farm_id}>
                  <S.FarmCardHeader>
                    <S.FarmCardText>
                      <h3>{farm.features[0].properties.farm_name}</h3>
                      <span>
                        {`${farm.features[0].properties.count_fields} Talhoes Cadastrados`}
                      </span>
                      <span>{calculateArea(farm)}</span>
                    </S.FarmCardText>
                    <S.ViewAction>
                      <button
                        type="button"
                        onClick={() => {
                          renderFarm(farm);
                          setSelectedFarmId(
                            farm.features[0].properties.farm_id
                          );
                          setSelectedFarmHa(
                            Number(farm.features[0].properties.geometry_ha)
                          );
                          setSelectedFarmGeoJson(farm);
                          setFarmName(farm.features[0].properties.farm_name);
                          setOwnerName(
                            farm.features[0].properties.contact_name
                          );
                          setDashboardState('dashboard@fields');
                        }}
                      >
                        <MdKeyboardArrowRight size={28} color="#444444" />
                      </button>
                    </S.ViewAction>
                  </S.FarmCardHeader>
                  <S.FarmCardActions>
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedFarmId(farm.features[0].properties.farm_id);
                        modifyFarm(farm);
                        setDashboardState('dashboard@editFarm_step1');
                      }}
                    >
                      <span>Editar</span>
                      <MdModeEdit />
                    </button>
                    <button
                      type="button"
                      className="simple-buttons"
                      onClick={() => {
                        openModal();
                        setDeleteFarm(farm);
                      }}
                    >
                      <span>Deletar</span>
                      <MdDelete />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        openModal();
                        setShareFarmId(farm);
                        handleShareControlList(
                          farm.features[0].properties.farm_id
                        );
                      }}
                    >
                      <span>Compartilhar</span>
                      <MdShare />
                    </button>
                  </S.FarmCardActions>
                </S.FarmCardContainer>
              );
            })
          )}
        </S.ListFarmsContainer>
        <S.RegisterFarmDialog>
          <h3>Cadastrar Fazenda</h3>
          <span>
            Cadastre sua fazenda e comece a usar as funcionalidades da
            plataforma.
          </span>
          <button
            type="button"
            onClick={() => setDashboardState('dashboard@registerFarm')}
          >
            Adicionar Fazenda
          </button>
          {/* <p>ou</p> */}
        </S.RegisterFarmDialog>
        <S.CardImportShapefile>
          <label htmlFor="upload-file-farm" className="label-upload">
            <MdCloudUpload size={22} color="#9FBC38" />
            <div>
              <span>Fazer o upload do shapefile</span>
              <br />
              <span className="subtitleBtn">
                Formatos aceitos: .zip, .kml, .kmz
              </span>
            </div>
            <input
              type="file"
              accept=".zip, .kml, .kmz"
              id="upload-file-farm"
              multiple={false}
              onChange={(e) => {
                const qtdArray = e.target.files.length;
                const ext = e.target.files[qtdArray - 1]?.name?.split('.');
                if (
                  e.target.files[qtdArray - 1].type !== 'application/zip' &&
                  e.target.files[qtdArray - 1].type !==
                  'application/vnd.google-earth.kml+xml' &&
                  e.target.files[qtdArray - 1].type !==
                  'application/vnd.google-earth.kmz' &&
                  e.target.files[qtdArray - 1].type !==
                  'application/x-zip-compressed' &&
                  ext[ext.length - 1] !== 'kmz' &&
                  ext[ext.length - 1] !== 'kml' &&
                  ext[ext.length - 1] !== 'zip'
                ) {
                  return toast(
                    <Toast
                      title="Shapefile no formato incorreto"
                      content="Formatos aceitos: .zip, .kml, .kmz "
                    />,
                    {
                      type: 'error'
                    }
                  );
                }
                handleUploadFile(
                  e.target.files[qtdArray - 1],
                  e.target.files[qtdArray - 1].type === ''
                    ? ext[ext.length - 1]
                    : e.target.files[qtdArray - 1].type
                );
              }}
            />
          </label>
        </S.CardImportShapefile>
        {dashboardState === 'dashboard@farm' && (
          <>
            {!!deleteFarm && (
              <ConfirmDialog
                messageDialog={`Deseja realmente excluir a fazenda ${deleteFarm?.features[0]?.properties?.farm_name} ?`}
                alert="ATENÇÃO: Ao deletar uma fazenda todos os talhões, subdivisões, caminhamentos e pontos de coleta seram apagados."
                farm={deleteFarm}
              />
            )}
            {!!shareFarmId && (
              <ShareModal
                messageDialog={`Compartilhamento  da fazenda ${shareFarmId?.features[0]?.properties?.farm_name} ?`}
                farm={shareFarmId}
              />
            )}
          </>
        )}
      </S.Container>
    </FarmContext.Provider>
  );
};

export default Farm;
