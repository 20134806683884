import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../../../components/Button';
import Input from '../../../../../../components/Input';
import SimpleButton from '../../../../../../components/SimpleButton';
import Toast from '../../../../../../components/Toast';
import InstructionsToast from '../../../../../../components/Toast/Instructions';
import { appConfig } from '../../../../../../config';
import { parsePhone } from '../../../../../../helpers/parseValues';
import { useAuth } from '../../../../../../hooks/Auth';
import { useMap } from '../../../../../../hooks/Map';
import api from '../../../../../../services/api';
import { DashboardContext } from '../../../../index';
import * as S from './styled';

export const DrawingFarmContext = createContext({});

const DrawingFarm = () => {
  const { buttonDisable } = useContext(DrawingFarmContext);
  const { farm, removeGeometries, removeAllInterections } = useMap();
  const [laboratory, setLaboratory] = useState();
  const { token } = useAuth();
  const { setDashboardState, farms, setFarms } = useContext(DashboardContext);
  const FarmRegisterSchema = Yup.object().shape({
    farm_name: Yup.string().required('Nome da fazenda é obrigatório'),
    contact_name: Yup.string(),
    contact_number: Yup.string()
  });
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(FarmRegisterSchema)
  });
  const onSubmit = async (data) => {
    const parsedContactNumber = parsePhone(data.contact_number);
    farm.features[0].properties.farm_name = data.farm_name;
    farm.features[0].properties.contact_number = parsedContactNumber;
    farm.features[0].properties.contact_name = data.contact_name;
    try {
      const response = await api.put(
        `${appConfig.apiHosts.farm}/Farm/UpdateFarm`,
        farm,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const [farmIndex] = farms
        .map((value, index) => {
          return value.features[0].properties.farm_id ===
            response.data.data.features[0].properties.farm_id
            ? index
            : -1;
        })
        .filter((index) => index !== -1);
      const newFarms = farms;
      newFarms[farmIndex] = response.data.data;
      setFarms(newFarms);
      removeAllInterections();
      setDashboardState('dashboard@farm');
      toast(
        <Toast
          title={`Fazenda ${response.data.data.features[0].properties.farm_name} editada com sucesso`}
        />,
        { type: 'success' }
      );
    } catch (error) {
      toast(
        <Toast
          title="Não foi possivel editar fazenda"
          content="Tente Novamente"
        />,
        { type: 'error' }
      );
    }
  };

  useEffect(() => {
    toast(
      <InstructionsToast title="Altere a área da sua propriedade no mapa">
        <p>
          1. Use o mouse para alterar a área correspondente à sua propriedade.
          <br />
          2. Após editar o polígono no mapa clique em editar fazenda.
        </p>
      </InstructionsToast>,
      {
        type: 'default',
        autoClose: 5000,
        hideProgressBar: true,
        style: { marginTop: '25%' }
      }
    );
  }, []);
  return (
    <DrawingFarmProvider>
      <S.Container>
        <S.ContainerBackButton>
          <SimpleButton
            text="Voltar"
            onClick={() => {
              setDashboardState('dashboard@farm');
              removeGeometries();
              removeAllInterections();
            }}
          />
        </S.ContainerBackButton>
        <S.RegisterFarmDialog>
          <h3>Editando Fazenda</h3>
          <span>
            Corrija a geometria da sua fazenda e continue a usar as
            funcionalidades da plataforma.
          </span>
        </S.RegisterFarmDialog>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              type="text"
              name="farm_name"
              placeholder="Nome da fazenda"
              ref={register}
              defaultValue={farm.features[0].properties?.farm_name}
            />
            <Input
              type="text"
              name="contact_number"
              id="contact_number"
              placeholder="Telefone de contato"
              ref={register}
              defaultValue={farm.features[0].properties?.contact_number}
            />
            <Input
              type="text"
              name="contact_name"
              placeholder="Contato da fazenda"
              ref={register}
              defaultValue={farm.features[0].properties?.contact_name}
            />
          </div>

          <Button
            text="Editar Fazenda"
            type="submit"
            disabled={buttonDisable}
          />
        </S.Form>
      </S.Container>
    </DrawingFarmProvider>
  );
};

export const DrawingFarmProvider = ({ children }) => {
  const [buttonDisable, setButtonDisable] = useState(true);

  return (
    <DrawingFarmContext.Provider value={{ buttonDisable, setButtonDisable }}>
      {children}
    </DrawingFarmContext.Provider>
  );
};

DrawingFarmProvider.propTypes = {
  children: PropTypes.node
};

export default DrawingFarm;
