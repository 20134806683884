/* eslint-disable prettier/prettier */
/* eslint-disable no-lone-blocks */
/* eslint-disable prettier/prettier */
/* eslint-disable linebreak-style */
/* eslint-disable function-paren-newline */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import { toast } from 'react-toastify';
import { shade, setLightness } from 'polished';
import Button from '../../../../components/Button';
import CardSelect from '../../../../components/CardSelect';
import Toast from '../../../../components/Toast';
import { useAuth } from '../../../../hooks/Auth';
import { useMap } from '../../../../hooks/Map';
import api from '../../../../services/api';
import { DashboardContext } from '../../index';
import * as S from './styled';
import { getDepth, setDepth } from '../../../../helpers/parseDephPoint';
import { appConfig } from '../../../../config';

const Results = () => {
  const [param, setParam] = useState('');
  const [layer, setLayer] = useState('');
  const [resultsVisible, setResultsVisible] = useState(true);
  const [addOtherMapsVisible, setAddOtherMapsVisible] = useState(false);
  const [listMapsVisible, setListMapsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseParams, setResponseParams] = useState();
  const ParamOptions = ['Argila', 'MO', 'MO x Arg', 'PH', 'Ca', 'Mg', 'K', 'AI', 'CTC', 'V%', 'P', 'Sal. AI', 'S'];
  const DepthOptions = ['0-10', '0-20', '0-20-40', '0-10-20', '0-10-20-40', '0-10-20-30-40', '0-10-20-30-40-60'];
  const mapId = nextId();
  const [background, setBackground] = useState();
  const [fertilizeList, setFertilizeList] = useState([]);
  const [fertilize, setFertilize] = useState('');
  const [mapType, setMapType] = useState('results');
  const [disabledAddBtn, setDisabledAddBtn] = useState(true);

  const {
    dashboardState,
    setDashboardState,
    setSubdivisions,
    resultVisible,
    setResultVisible,
    setFields,
    subdivisions
  } = useContext(DashboardContext);
  const { token, user } = useAuth();
  const {
    selectedFarmId,
    setListSubdivisions,
    renderSubdivisions,
    setLegendResultsValue,
    setLegendResultsValue1,
    setLegendResultsValue2,
    setLegendResultsValue3,
    setLegendResultsValue4,
    legendResultsValue1,
    renderSubdivisionsWithColors,
    listSubdivisions,
    removeMappingGeometries,
    removePointGeometries,
    renderedMapEditId,
    setRenderedMapEditId,
    renderedMaps,
    renderFarm,
    selectedFarmGeoJson,
    mapRef,
    setListFields,
    renderFields,
    refMap,
    removeGeometriesColoredSubdivisions,
    removeRecommendationZone,
    swipeShow,
    setSwipeShow,
    swipeActived,
    setSwapActived,
    activedSwipe,
    addSwipe,
    removeGeometriesSwipe,
    removeSwipe,
    setBtnColor,
    removeColoredRecommendationZone,
    setParameterResults,
    setParameterResults1,
    setParameterResults2,
    setParameterResults3,
    setParameterResults4,
    parameterResults1,
    renderSubdivisionsWithColorsDepth2040,
    setTableResults,
    contactList,
    setContactList,
    setChatList,
    renderRecommendationZoneWithColors,
    setLegendRecommendationValue,
    recommendationMapSubtitle,
    setRecommendationMapSubtitle,
    recommZoneList,
    recommendationsZoneList,
    setRecommendationsZoneList,
    renderRecommendationZones,
    setRecommZoneList,
    // fertilize,
    // setFertilize
  } = useMap();

  useEffect(() => {
    if (renderedMapEditId && renderedMaps) {
      setLayer(renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].layer);
      setParam(renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].param);
      setFertilize(renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].fertilize);
      setMapType(renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].mapType);
      setBackground(renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].color);
    }
    removeRecommendationZone();
  }, []);

  const getSubdivisions = async () => {
    try {
      setLoading(true);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Subdivision/GetActiveSubdivisionListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSubdivisions(response.data.data);
      renderSubdivisions(response.data.data);
      setListSubdivisions(response.data.data);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getFields = async () => {
    try {
      setFields([]);
      setListFields([]);
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.field}/Field/GetActiveFieldListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFields(response.data.data);
      setListFields(response.data.data);
      renderFields(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setFields([]);
    }
  };

  useEffect(() => {
    getSubdivisions();
    getFields();
  }, [refMap]);

  const handleViewParam = async (parameter, depth) => {
    setSwipeShow(false);
    setSwapActived(false);
    try {
      if (depth === '20-40') {
        removeGeometriesColoredSubdivisions();
        const params02040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-20-40']
        ]);
        const resp02040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params02040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const params0102040 = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', '0-10-20-40']
        ]);
        const resp0102040 = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params: params0102040,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const subdivisonsColors = [];
        subdivisonsColors.push(resp02040.data.data);

        resp0102040.data.data.forEach((color) => {
          subdivisonsColors[0].push(color);
        });

        renderSubdivisionsWithColors(listSubdivisions, subdivisonsColors[0]);
      } else {
        const params = new URLSearchParams([
          ['farmId', selectedFarmId],
          ['parameter', parameter],
          ['depth', setDepth(depth)]
        ]);
        const response = await api.get(
          `${appConfig.apiHosts.report}/Report/GetSubdivisionColorDepthByFarmId`,
          {
            params,
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        // const responseParams = response.data.data;
        renderSubdivisionsWithColors(listSubdivisions, response.data.data);
      }

      if (parameter && depth) {
        setSwipeShow(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateLegend = async (parameter) => {
    try {
      const params = new URLSearchParams([['ParamId', parameter]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/ReportLegend/GetReportLegendByParam`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      // const responseParams = response.data.data;
      setLegendResultsValue(response.data.data);
      setParameterResults(parameter);

      if (renderedMaps.length === 0) {
        setLegendResultsValue1(response.data.data);
      }
      if (renderedMaps.length === 1) {
        setLegendResultsValue2(response.data.data);
      }
      if (renderedMaps.length === 2) {
        setLegendResultsValue3(response.data.data);
      }
      if (renderedMaps.length === 3) {
        setLegendResultsValue4(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditRenderedMap = () => {
    const renderedMap = renderedMaps?.filter((value) => value.id === renderedMapEditId)[0];
    renderedMap.layer = layer;
    renderedMap.param = param;
    renderedMap.fertilize = fertilize.fertilize;
    renderedMap.color = background;
    renderedMap.mapType = mapType;
  };

  const getReport = async () => {
    try {
      const params = new URLSearchParams([['farmId', selectedFarmId]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/Report/GetReportListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setTableResults(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!selectedFarmId) {
      return toast(
        <Toast
          title="Por favor selecione uma fazenda"
          content="Para que as subdivisões sejam carregadas é nescessário selecionar uma fazenda"
        />,
        { type: 'warning' }
      );
    }
    getSubdivisions();
    setResultVisible(true);
    setLegendResultsValue([]);
    removeMappingGeometries();
    removePointGeometries();
    removeColoredRecommendationZone();
    getReport();
  }, []);

  const handleClick = () => {
    renderedMaps.push({
      id: mapId,
      map: `Mapa ${renderedMaps.length + 1}`,
      param,
      layer,
      fertilize: fertilize.fertilize,
      color: background,
      recommendationType: fertilize.recommendation_type,
      mapType
    });
  };

  const generateColoredZone = async (color) => {
    setSwipeShow(false);
    setSwapActived(false);
    setRecommendationMapSubtitle([]);
    setLegendRecommendationValue([]);
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationsListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const selectedRecommZoneBytFert = response.data.data.filter((v) => v.fertilize === fertilize.fertilize);

      const selectedRecommZone = [];

      recommZoneList.forEach(async (v) => {
        if (selectedRecommZoneBytFert.length > 0) {
          await selectedRecommZoneBytFert.forEach((zone) => {
            if (zone.recommendation_zone_id === v.features[0].properties.recommendation_zone_id) {
              selectedRecommZone.push(v);
            }
          });
        }
      });

      const quantity = selectedRecommZoneBytFert
        .map((value) => value.quantity)
        .filter((value, index, self) => self.indexOf(value) === index);

      const itemFilter = [];

      selectedRecommZoneBytFert.forEach(async (item) => {
        if (itemFilter.length <= 0) {
          itemFilter.push(item);
        } else {
          itemFilter.forEach((value) => {
            if (value?.fertilize !== item.fertilize) {
              itemFilter.push(item);
            }
          });
        }
      });

      const sort = await selectedRecommZoneBytFert.sort((n1, n2) => n2.quantity - n1.quantity);

      const cores = selectedRecommZoneBytFert.map((value) => {
        sort.forEach((qt, index) => {
          if (value.quantity === qt.quantity) {
            value = {
              ...value,
              color: `${setLightness((index + 1) / 10, color)}`
            };
          }
        });
        return value;
      });

      cores.forEach((item, index) => {
        recommendationMapSubtitle.push({
          quantity: item.quantity,
          fertilize: item.fertilize,
          measurement_unit: item.measurement_unit,
          color: `${setLightness((index + 1) / 10, color)}`
        });
      });

      renderRecommendationZoneWithColors(selectedRecommZone, cores);
      setLegendRecommendationValue(recommendationMapSubtitle);
      if (fertilize && background) {
        setSwipeShow(true);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getFertilizesList = async () => {
    try {
      const params = new URLSearchParams([
        ['farmId', selectedFarmId],
        ['userId', user.user_id]
      ]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationsListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setRecommendationsZoneList(response.data.data);

      response.data.data.forEach((result) => {
        if (fertilizeList.length <= 0) {
          fertilizeList.push(result);
        } else {
          const fertFilter = fertilizeList?.filter((v) => v.fertilize === result.fertilize);

          if (fertFilter.length <= 0) {
            fertilizeList.push(result);
          }
        }
      });

      if (renderedMapEditId) {
        const fertFilter = fertilizeList.filter((v) => v.fertilize === renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].fertilize)[0];
        setFertilize(fertFilter);
      }

      await setBackground(renderedMaps?.filter((value) => value.id === renderedMapEditId)[0].color);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getRecommZoneList = async () => {
    try {
      if (!selectedFarmId) {
        return toast(
          <Toast
            title="Por favor selecione uma fazenda"
            content="Para que as sejam carregadas as listas de zona de recomendascoes é necessario selecionar uma fazenda"
          />,
          { type: 'warning' }
        );
      }

      const params = new URLSearchParams([['farmId', selectedFarmId], ['userId', user.user_id]]);
      const response = await api.get(
        `${appConfig.apiHosts.report}/RecommendationZone/GetRecommendationZoneListByFarmId`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setRecommZoneList(response.data.data);
      // renderRecommendationZones(response.data.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleChangeComplete = (color) => {
    setBackground(color);
    generateColoredZone(color);
  };

  useEffect(() => {
    getFertilizesList();
    getRecommZoneList();
  }, []);

  useEffect(() => {
    if (background !== '' && fertilize !== '') {
      handleChangeComplete(background);
    }
  }, [background, fertilize]);

  useEffect(() => {
    handleGenerateLegend(param);
  }, [param]);

  useEffect(() => {
    getSubdivisions();
  }, [param]);

  useEffect(() => {
    if (mapType === 'results' && layer !== '' && param !== '') {
      setDisabledAddBtn(false);
    } else if (mapType === 'recommendationMap' && fertilize !== '' && background) {
      setDisabledAddBtn(false);
    } else {
      setDisabledAddBtn(true);
    }
  }, [param, layer, fertilize, background]);

  useEffect(() => {
    setDisabledAddBtn(true);
    setParam('');
    setLayer('');
    setFertilize('');
    setBackground('');
    setLegendRecommendationValue('');
    removeColoredRecommendationZone();
    removeGeometriesColoredSubdivisions();
  }, [mapType]);

  return (
    <S.Container>
      <>
        {resultsVisible && selectedFarmId && (
          <>
            <h1>Resultados de Análise</h1>
            <hr />
            <p>Selecione o tipo de Mapa que deseja visualizar</p>
            <S.ZoneCardActions>
              <S.ActionButtonCard
                status={mapType === 'results'}
                onClick={() => setMapType('results')}
              >
                Resultados de Análise
              </S.ActionButtonCard>
              <S.ActionButtonCard
                status={mapType === 'recommendationMap'}
                onClick={() => setMapType('recommendationMap')}
              >
                Mapa de Recomendação
              </S.ActionButtonCard>
            </S.ZoneCardActions>
          </>
        )}
      </>
      {mapType === 'results' && (
        <>
          <p>
            Utilize as opções abaixo para adicionar as camadas desejadas no mapa
            ao lado
          </p>
          <S.Content>
            {!selectedFarmId ? (
              <h3>Por favor selecione uma fazenda</h3>
            ) : (
              <>
                {!listMapsVisible && (
                  <>
                    <S.TitleBox>Escolha o parâmetro</S.TitleBox>
                    <S.DivTags>
                      {ParamOptions.map((value) => {
                        return (
                          <CardSelect
                            text={value}
                            onClick={() => {
                              setParam(value);
                              handleViewParam(value, layer);
                            }}
                            selected={param === value}
                          />
                        );
                      })}
                    </S.DivTags>

                    <S.TitleBox>Escolha a camada</S.TitleBox>
                    <S.DepthContainer>
                      <CardSelect
                        text={getDepth(DepthOptions[0])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[0]));
                          handleViewParam(param, getDepth(DepthOptions[0]));
                        }}
                        selected={layer === getDepth(DepthOptions[0])}
                      />
                      <CardSelect
                        text={getDepth(DepthOptions[3])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[3]));
                          handleViewParam(param, getDepth(DepthOptions[3]));
                        }}
                        selected={layer === getDepth(DepthOptions[3])}
                      />
                      <CardSelect
                        text={getDepth(DepthOptions[2])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[2]));
                          handleViewParam(param, getDepth(DepthOptions[2]));
                        }}
                        selected={layer === getDepth(DepthOptions[2])}
                      />
                      <br />
                    </S.DepthContainer>
                    <S.DepthContainer style={{ marginBottom: '21px' }}>
                      <CardSelect
                        text={getDepth(DepthOptions[1])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[1]));
                          handleViewParam(param, getDepth(DepthOptions[1]));
                        }}
                        selected={layer === getDepth(DepthOptions[1])}
                      />
                      <CardSelect
                        text={getDepth(DepthOptions[5])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[5]));
                          handleViewParam(param, getDepth(DepthOptions[5]));
                        }}
                        selected={layer === getDepth(DepthOptions[5])}
                      />
                      <CardSelect
                        text={getDepth(DepthOptions[6])}
                        onClick={() => {
                          setLayer(getDepth(DepthOptions[6]));
                          handleViewParam(param, getDepth(DepthOptions[6]));
                        }}
                        selected={layer === getDepth(DepthOptions[6])}
                      />
                    </S.DepthContainer>
                  </>
                )}
              </>
            )}
          </S.Content>
        </>
      )}

      {mapType === 'recommendationMap' && (
        <>
          {!selectedFarmId ? (
            <h3>Por favor selecione uma fazenda</h3>
          ) : (
            <S.Content>
              <S.TitleBox>Escolha o fertilizante/corretivo</S.TitleBox>
              <S.DivTags>
                {fertilizeList.map((value) => {
                  return (
                    <CardSelect
                      text={value.fertilize}
                      onClick={() => {
                        setFertilize(value);
                        // handleViewParam(value, layer);
                      }}
                      selected={fertilize === value}
                    />
                  );
                })}
              </S.DivTags>
              <p>
                Utilize as opções abaixo e selecione uma cor para visualizar o mapa de recomendação
              </p>
              <S.ContentSelectColor>
                <S.ColorPickerContainer>
                  <S.ColorArea
                    color="#800000"
                    selected={background}
                    onClick={() => {
                      handleChangeComplete('#800000');
                    }}
                  />
                  <S.ColorArea
                    color="#000040"
                    selected={background}
                    onClick={() => handleChangeComplete('#000040')}
                  />
                  <S.ColorArea
                    color="#004000"
                    selected={background}
                    onClick={() => handleChangeComplete('#004000')}
                  />
                  <S.ColorArea
                    color="#BE3C08"
                    selected={background}
                    onClick={() => handleChangeComplete('#BE3C08')}
                  />
                  <S.ColorArea
                    color="#400040"
                    selected={background}
                    onClick={() => handleChangeComplete('#400040')}
                  />
                  <S.ColorArea
                    color="#BA9910"
                    selected={background}
                    onClick={() => handleChangeComplete('#BA9910')}
                  />
                </S.ColorPickerContainer>
              </S.ContentSelectColor>
            </S.Content>
          )}
        </>
      )}

      {!renderedMapEditId && selectedFarmId && renderedMaps.length < 4 && (
        <Button
          text="Adicionar outro mapa"
          onClick={() => {
            handleClick();
            setDashboardState('dashboard@renderedMaps');
          }}
          className="buttonAddMap"
          disabled={disabledAddBtn}
        />
      )}
      {selectedFarmId && renderedMaps.length <= 4 && renderedMapEditId && (
        <Button
          text={renderedMapEditId ? 'Editar Parametros' : 'Adicionar outro mapa'}
          onClick={() => {
            handleEditRenderedMap();
            setRenderedMapEditId();
            setDashboardState('dashboard@renderedMaps');
          }}
          className="buttonAddMap"
        />
      )}
    </S.Container>
  );
};
export default Results;
